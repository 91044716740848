import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CButton, CCard, CCardBody, CCardGroup, CCol, CContainer, CForm, CFormInput, CInputGroup, CInputGroupText, CRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { useForm } from 'react-hook-form'
import Vakilkaro from "../../../assets/brand/vakilkaro";
import { userLogin } from 'src/api/loginApi'
import { errorAlert, successAlert } from 'src/utils/swalert'

const Login = () => {

  const [userName, setUserName] = useState('');
  const [userPassword, setUserPassword] = useState('')

  let navigate = useNavigate()

  const { handleSubmit } = useForm()

  const onSubmit = (data) => {
  }

  const handleLogin = () => {
    userLoginApi();
  }

  const userLoginApi = () => {
    const userData =
    {
      "email": userName,
      "password": userPassword,
    }
    userLogin(userData)
      .then((res) => {
        if (res.status === "success") {
          localStorage.setItem('token', res.token)
          navigate("/");
          successAlert("Login Successfully", "Welcome to the dashboard!")
        }
        else {
          console.log("login failed")
        }
      })
      .catch((err) => {
        console.log("Server Error", err)
        errorAlert("login failed !", "User name or password is incorrect")
      })
  }

  return (
    <div style={{ width: '100vw', height: '100vh' }} className='bg-img'>
      <div className=" min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={4}>
              <CCardGroup>
                <CCard className="p-4" style={{ backgroundColor: '#f4f8f6c2', }}>
                  <CCardBody>
                    <CForm className="align_center"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div>
                        <div className='mb-4'><Vakilkaro /></div>
                        <div className='align_center'>
                          <h2>Login</h2>
                        </div>
                      </div>

                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <CFormInput
                          placeholder="Username"
                          autoComplete="username"
                          name="username"
                          onChange={(e) => setUserName(e.target.value)}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          name="Cpassword"
                          onChange={(e) => setUserPassword(e.target.value)}
                        />
                      </CInputGroup>
                      <CRow>
                        <CCol xs={6}>
                          <CButton
                            onClick={() => handleLogin()}
                            type="submit" color="primary" className="px-4">
                            Login
                          </CButton>
                        </CCol>
                        {/* <CCol xs={6} className="text-right">
                        <CButton onClick={() => navigate('')} color="link" className="px-0">
                          Forgot password?
                        </CButton>
                      </CCol> */}
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </div>
  )
}

export default Login
