import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CForm, CButton, CCard, CCardBody, CCardHeader, CRow, CCol, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow, CFormLabel } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import { addCategories, getAllCategories, getSingleCategories, updateCategory } from "src/api/blog";
import { getAllBlogCategory } from "src/api/blogCategories";

const BlogCategory = () => {
  const [categoryAction, setCategoryAction] = useState("create");
  const [categoryId, setCategoryId] = useState("");
  const [categoryData, setCategoryData] = useState(null);
  const ref = useRef(null);
  const [categoryTitle, setCategoryTitle] = useState("");

  useEffect(() => {
    getAllCategory();
  }, []);

  //category submit
  const handleCategorySection = () => {
    const payload = { title: categoryTitle };
    if (categoryAction === "create") {
      addCategories(payload)
        .then((res) => {
          getAllCategory();
         
          setCategoryTitle("");
          setCategoryAction("create");
        })
        .catch((err) => alert("err", err));
    } else {
      updateCategory(categoryId, payload)
        .then((res) => {
          getAllCategory();
          setCategoryTitle("");
          setCategoryAction("create");
        })
        .catch((err) => alert("err", err));
    }
  };

  //getSingleCategory
  const getSingleCategory = (id) => {
    getSingleCategories(id)
      .then((res) => {
        setCategoryTitle(res.data.title);
        setCategoryAction("edit");
        setCategoryId(id);
        scrollElement();
      })
      .catch((err) => alert("err", err));
  };

  //get All Category
  const getAllCategory = () => {
    getAllBlogCategory()
      .then((res) => setCategoryData(res?.data))
      .catch((err) => alert("err", err));
  };

  const scrollElement = () => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <CCard>
        <CCardHeader className="form-header" ref={ref}>
          {categoryAction === "create"
            ? "Create Category"
            : categoryAction === "edit"
              ? "Edit Category"
              : ""}
        </CCardHeader>
        <CCardBody>
          <CForm>
            <CRow className="align-items-start">
              <CCol style={{ display: 'flex', alignItems: 'center' }} md={7}>
                <CFormLabel htmlFor="title" style={{ marginRight: 10 }}>Title</CFormLabel>
                <CFormInput
                  type="text"
                  id="title"
                  name="title"
                  value={categoryTitle}
                  onChange={(e) => setCategoryTitle(e.target.value)}
                  aria-describedby="title"
                />
              </CCol>
       
              <CCol md={2}>
                <CButton onClick={handleCategorySection}>{categoryAction === "create" ? "Create" : "Update"}</CButton>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
      <br />
      <CCard>
        <CCardHeader className="form-header" ref={ref}>
          List of Categories
        </CCardHeader>
        <CCardBody>
          <CTable responsive className="table table-bordered">
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">S.No.</CTableHeaderCell>
                <CTableHeaderCell scope="col">Title</CTableHeaderCell>
                <CTableHeaderCell scope="col">Action</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {categoryData?.length > 0 &&
                categoryData?.map((item, index) => {
                  return (
                    <CTableRow key={index}>
                      <CTableDataCell>{index + 1}</CTableDataCell>
                      <CTableDataCell>{item?.title}</CTableDataCell>
                      <CTableDataCell>
                        <>
                          <Tooltip text="Update">
                            <FontAwesomeIcon
                              role="button"
                              icon={faPenToSquare}
                              size="1x"
                              className="px-3 updateIcon"
                              onClick={() => getSingleCategory(item?.id)}
                            />
                          </Tooltip>
                        </>
                      </CTableDataCell>
                    </CTableRow>
                  );
                })}
            </CTableBody>
          </CTable>
        </CCardBody>
        <br />
      </CCard>
    </>
  );
}

export default BlogCategory;
