import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CForm, CButton, CCard, CCardBody, CCardHeader, CRow, CCol, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow, CFormLabel, CFormSelect } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import { addSubCategories, getAllBlogSubCategory, getSingleSubCategories, updateSubCategory } from "src/api/blogSubCategories";
import { getAllBlogCategory } from "src/api/blogCategories";

const BlogSubCategory = () => {
  const [categoryAction, setCategoryAction] = useState("create");
  const [categoryId, setCategoryId] = useState("");
  const [categoryData, setCategoryData] = useState(null);
  const [subCategoryData, setSubCategoryData] = useState(null);
  const ref = useRef(null);
  const [categoryTitle, setCategoryTitle] = useState("");

  const [title, setTitle] = useState("");


  useEffect(() => {
    getAllSubCategory();
    getAllCategory();
  }, []);

  //category submit
  const handleSubCategorySection = () => {
    const payload = { title, categoryId: categoryTitle };
    if (categoryAction === "create") {
      addSubCategories(payload)
        .then((res) => {
          getAllSubCategory();
          setCategoryTitle("");
          setCategoryAction("create");
        })
        .catch((err) => alert("err", err));
    } else {
      updateSubCategory(categoryId, payload)
        .then((res) => {
          getAllSubCategory();
          setCategoryTitle("");
          setCategoryAction("create");
        })
        .catch((err) => alert("err", err));
    }
  };

  //getSingleCategory
  const getSingleCategory = (id) => {
    getSingleSubCategories(id)
      .then((res) => {
        setCategoryAction("edit");
        setCategoryId(id);
        scrollElement();
      })
      .catch((err) => alert("err", err));
  };

  //get All Category
  const getAllSubCategory = () => {
    getAllBlogSubCategory()
      .then((res) => setSubCategoryData(res?.data))
      .catch((err) => alert("err", err));
  };

  const getAllCategory = () => {
    getAllBlogCategory()
      .then((res) => setCategoryData(res?.data))
      .catch((err) => alert("err", err));
  };


  const scrollElement = () => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <CCard>
        <CCardHeader className="form-header" ref={ref}>
          {categoryAction === "create"
            ? "Create Sub Category"
            : categoryAction === "edit"
              ? "Edit Category"
              : ""}
        </CCardHeader>
        <CCardBody>
          <CForm>
            <CRow className="align-items-start">
              <CCol style={{ display: 'flex', alignItems: 'center' }} md={7}>
                <CFormLabel htmlFor="title" style={{ marginRight: 10 }}>Title</CFormLabel>
                <CFormInput
                  type="text"
                  id="title"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  aria-describedby="title"
                />
              </CCol>
              <CCol style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }} md={7}>
                <CFormLabel htmlFor="subcategory" style={{ marginRight: 10 }}>Subcategory</CFormLabel>
                <CFormSelect
                  id="subcategory"
                  name="subcategory"
                  // value={subcategory}
                  value={categoryTitle}
                  onChange={(e) => setCategoryTitle(e.target.value)}

                  // onChange={(e) => setSelectSubCategory(e.target.value)}
                  aria-describedby="Category"
                >
                  <option value="">Select Category</option>
                  {categoryData?.length > 0 &&
                    categoryData.map((category, index) => (
                      <option key={index} value={category.id}>
                        {category.title}
                      </option>
                    ))
                  }

                </CFormSelect>
              </CCol>
              <CCol md={2}>
                <CButton onClick={handleSubCategorySection}>{categoryAction === "create" ? "Create" : "Update"}</CButton>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
      <br />
      <CCard>
        <CCardHeader className="form-header" ref={ref}>
          List of Categories
        </CCardHeader>
        <CCardBody>
          <CTable responsive className="table table-bordered">
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">S.No.</CTableHeaderCell>
                <CTableHeaderCell scope="col">Title</CTableHeaderCell>
                <CTableHeaderCell scope="col">Category Title</CTableHeaderCell>
                <CTableHeaderCell scope="col">Action</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {subCategoryData?.length > 0 &&
                subCategoryData?.map((item, index) => {
                  return (
                    <CTableRow key={index}>
                      <CTableDataCell>{index + 1}</CTableDataCell>
                      <CTableDataCell>{item?.title}</CTableDataCell>
                      <CTableDataCell>{item.blogPageCategoriesSection.title}</CTableDataCell>
                      <CTableDataCell>
                        <>
                          <Tooltip text="Update">
                            <FontAwesomeIcon
                              role="button"
                              icon={faPenToSquare}
                              size="1x"
                              className="px-3 updateIcon"
                              onClick={() => getSingleCategory(item?.id)}
                            />
                          </Tooltip>
                        </>
                      </CTableDataCell>
                    </CTableRow>
                  );
                })}
            </CTableBody>
          </CTable>
        </CCardBody>
        <br />
      </CCard>
    </>
  );
}

export default BlogSubCategory;
