import React from 'react'
import { AppContent, AppSidebar, AppHeader, Footer } from '../../components'

const Home = () => {
    return (
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader />
                <div className="body flex-grow-1 px-3">
                    <AppContent />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Home
