import React from "react";
import {
  CModal,
  CButton,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import PropTypes from "prop-types";

const DeletePopMsg = ({ visible, setVisible, id, deleteFunction }) => {

  return (
    <>
      <CModal
        backdrop="static"
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="StaticBackdropExampleLabel"
      >
        <CModalHeader>
          <CModalTitle id="StaticBackdropExampleLabel">
            Delete Confirmation{" "}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>are you sure you want to delete your details</CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            Cancel
          </CButton>
          <CButton
            className="btn btn-danger"
            onClick={() => {
              deleteFunction(id);
            }}
          >
            Delete
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};
DeletePopMsg.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  deleteFunction: PropTypes.func.isRequired,
};

export default DeletePopMsg;
