// import SubHeader from '../../components/HeadersPage/SubHeader'
import React, { useState, useEffect } from "react";
import {
  CFormInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormLabel,
  CFormSelect,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import DeletePopMsg from "src/components/modal/DeletePopMsg";
import {
  addSubMenu,
  getSubMenu,
  getSubMenuById,
  updateSubMenu,
  deleteSubMenu,
} from "src/api/header";
import { getHeaders } from "src/api/header";
import ReactPaginate from "react-js-pagination";

const subMenu = {
  submenuName: "",
  headerId: "",
};

const SubMenu = () => {

  const [subMenuAction, setSubMenuAction] = useState("create");
  const [subMenuId, setSubMenuId] = useState("");
  const [subMenuSection, setSubMenuSection] = useState(subMenu);
  const [subMenuData, setSubMenuData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [options, setOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [errors, setErrors] = useState({});


  useEffect(() => {
    getSubMenus();
    getAllOptions();
  }, []);

  const [clicked, setClicked] = useState({
    headerId: false,   
    submenuName: false,
  });

  const handleSubMenu = (e) => {
    const { name, value } = e.target;
    setSubMenuSection({ ...subMenuSection, [name]: value });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleInputFocus = (fieldName) => {
    setClicked({ ...clicked, [fieldName]: true });

    setErrors({ ...errors, [fieldName]: "" });
  };

  const handleSubMenuSection = () => {
    let formIsValid = true;
    const errorsCopy = { ...errors };

    if (!headerId) {
      errorsCopy.headerId = "Header ID is required";
      formIsValid = false;
    } else {
      errorsCopy.headerId = "";
    }
    if (!submenuName) {
      errorsCopy.submenuName = "sub-Menu Name is required";
      formIsValid = false;
    } else {
      errorsCopy.submenuName = "";
    }
    setErrors(errorsCopy);

    if(formIsValid){
    const payload = {
      submenuName: subMenuSection.submenuName,
      headerId: subMenuSection.headerId,
    };
    if (subMenuAction === "create") {
      addSubMenu(payload)
        .then((res) => {
          getSubMenus();
          setSubMenuSection(subMenu);
          setSubMenuAction("create");
        })
        .catch(err => console.log("err", err));
    } else {
      updateSubMenu(subMenuId, payload)
        .then((res) => {
          getSubMenus();
          setSubMenuSection(subMenu);
          setSubMenuAction("create");
        })
        .catch(err => console.log("err", err));
    }
  }
  };

  const getSingleSubMenu = (id) => {
    getSubMenuById(id)
      .then(res => setSubMenuSection(res.data))
      .catch(err => console.log("err", err))
  };

  const getSubMenus = () => {
    getSubMenu()
      .then(res => setSubMenuData(res?.data))
      .catch(err => console.log("err", err));
  };

  const deleteSubMenuData = (id) => {
    deleteSubMenu(id)
      .then((res) => {
        getSubMenus();
        setSubMenuSection(subMenu);
        setVisible(false);
        setSubMenuAction("create");
      })
      .catch((err) => console.log("err", err));
  };

  const getAllOptions = () => {
    getHeaders()
      .then((res) => {
        setOptions(
          res?.data?.map((data) => {
            return {
              label: data?.headerName,
              value: data?.id,
            };
          })
        );
      })
      .catch(err => console.log("err", err));
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = subMenuData?.slice(indexOfFirstRow, indexOfLastRow);

  const {submenuName,headerId}=subMenuSection;
  return (
    <>
      <CCard className="mb-4">
        <CCardHeader>
          {/* Create Sub-Menu */}
          <strong>
          {subMenuAction === "create"
          ? "Create Sub-Menu"
          : subMenuAction === "edit"
            ? "Edit Sub-Menu"
            : ""}
            </strong>
          </CCardHeader>
        <CCardBody>
          <CForm>
            <CRow className="align-items-start">              
              <CCol xs={12} md={5}>
              <CFormLabel>Sub Menu</CFormLabel>
              <span className="red-star">{` `}*</span>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  name="submenuName"
                  value={subMenuSection.submenuName}
                  onChange={handleSubMenu}
                  onFocus={() => handleInputFocus("submenuName")}
                  aria-describedby="exampleFormControlInputHelpInline"
                />
                {errors.submenuName && (
                  <div className="text-danger">{errors.submenuName}</div>
                )}               
                
              </CCol>

              <CCol xs={12} md={5} lg={5}>
                <CFormLabel>Header Name</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormSelect
                  size="md"
                  aria-label="Select Header Name"
                  id="role"
                  value={subMenuSection.headerId}
                  name="headerId"
                  onChange={handleSubMenu}
                  onFocus={() => handleInputFocus("headerId")}
                >
                  <option value="" disabled>
                    Select Header Name
                  </option>
                  {options?.map((optionData, index) => {
                    return (
                      <option key={index} value={optionData?.value}>
                        {optionData?.label}
                      </option>
                    );
                  })}
                </CFormSelect>
                {errors.headerId && (
                  <div className="text-danger">{errors.headerId}</div>
                )}
              </CCol>

              <CCol xs={12} md={2}>
                <div style={{height:'20px'}}>{" "}</div>
                <CButton onClick={handleSubMenuSection}>Submit</CButton>
              </CCol>

            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
      <CCard className="mb-4">
        <CCardHeader>
          {/* {subMenuAction === "create" ? "Create SubMenu" : "Edit SubMenu"} */}
          <strong>Detailed Submenu Listings</strong>
        </CCardHeader>

        <div className="search_bar">
          <div className="search-inner">
            <div>
              <CFormSelect
                size="sm"
                className="mb-3"
                style={{ cursor: "pointer" }}
                aria-label="Small select example"
                onChange={(e) => setRowsPerPage(e.target.value)}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </CFormSelect>
            </div>
            <div style={{ display: "flex" }}>
              {/* {showSelectAndSearch && ( */}
              <>
                <CFormInput
                  type="text"
                  size="sm"
                  placeholder="Search"
                  aria-label="sm input example"
                  style={{ margin: "0px 0px 16px 0px" }}
                  onChange={handleSearchChange}
                  value={searchInput}
                />
                <span
                  style={{
                    border: " 1px solid #d1c5c5",
                    borderRadius: "4px",
                    margin: "0px 5px 16px -1px",
                    padding: "1px 8px 0px 8px",
                    background: "#ebedef",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon role="button" icon={faSearch} size="1x" />
                </span>
              </>
              {/* )} */}
            </div>
          </div>
        </div>

        {/* top end */}
        <CCardBody className="pb-0">
          <CTable responsive striped hover className="table table-bordered custom-table-border row-hover">
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell className={"purpleRow"} scope="col">S.No.</CTableHeaderCell>
                <CTableHeaderCell className={"purpleRow"} scope="col">Sub Menu Name</CTableHeaderCell>
                <CTableHeaderCell className={"purpleRow"} scope="col">Header Name</CTableHeaderCell>
                <CTableHeaderCell className={"purpleRow"} scope="col">Action</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {currentRows?.length > 0 && (
                currentRows
                  ?.filter((item) =>
                    item?.submenuName
                      .toLowerCase()
                      .includes(searchInput.toLowerCase())
                  ))?.map((item, index) => {
                    return (
                      <CTableRow key={index}>
                        <CTableDataCell>{index + 1}</CTableDataCell>
                        <CTableDataCell>{item?.submenuName}</CTableDataCell>
                        <CTableDataCell>
                          {options ? options?.find(option => option.value === item?.headerId)?.label
                            : ""}
                        </CTableDataCell>
                        <CTableDataCell>
                          <>
                            <Tooltip text="Update">
                              <FontAwesomeIcon
                                role="button"
                                icon={faPenToSquare}
                                size="1x"
                                className="px-3 updateIcon"
                                onClick={() => {
                                  setSubMenuAction("edit");
                                  setSubMenuId(item?.id);
                                  getSingleSubMenu(item?.id);
                                }}
                              />
                            </Tooltip>

                            <Tooltip text="Delete">
                              <FontAwesomeIcon
                                role="button"
                                icon={faTrash}
                                size="1x"
                                className="deleteIcon"
                                onClick={() => {
                                  setSubMenuAction("create");
                                  setSubMenuId(item?.id);
                                  setVisible(true);
                                }}
                              />
                            </Tooltip>
                          </>
                        </CTableDataCell>
                      </CTableRow>
                    );
                  })}
            </CTableBody>
          </CTable>
          <div className="pagin-div">
            <div>
              Showing {indexOfFirstRow + 1} to{" "}
              {Math?.min(indexOfLastRow, subMenuData?.length)} of{" "}
              {subMenuData?.length} entries
            </div>
            <div className="pagination-container">
              <ReactPaginate
                activePage={currentPage}
                itemsCountPerPage={rowsPerPage}
                totalItemsCount={subMenuData?.length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                prevPageText="Previous"
                nextPageText="Next"
                firstPageText="First"
                lastPageText="Last"
                innerClass="pagination"
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </div>
        </CCardBody>
        <DeletePopMsg
          visible={visible}
          setVisible={setVisible}
          id={subMenuId}
          deleteFunction={deleteSubMenuData}
        />
      </CCard>
    </>
  );
};

export default SubMenu;