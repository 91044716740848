// import { provider } from "../provider";
import React, { useEffect, useState } from "react";
import {
  CFormInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CFormLabel,
} from "@coreui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllServices } from "src/api/service";
import { addBlogData, getBlogById, updateBlogs } from "src/api/blog";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AddWorkForm = () => {
  const route = useLocation();
  const { blogId, isUpdate } = route?.state;
  const initialState = {
    serviceId: "",
    metaDescription: "",
    metaTitle: "",
    slugUrl: "",
    title: "",
    metaKeyword: "",
  };

  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialState);
  const [options, setOptions] = useState();
  const [description, setdescription] = useState("");

  useEffect(() => {
    if (isUpdate) {
      getBlogsData();
    }
  }, [isUpdate]);

  useEffect(() => {
    getServices();
  }, []);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setdescription(data);
    // setTabSection({...tabSection,description:data})
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const [errors, setErrors] = useState({});

  const getBlogsData = () => {
    getBlogById(blogId)
      .then((res) => {
        setFormData(res?.data);
      })
      .catch((err) => console.log("Create Service Page From Get", err));
  };

  const handleSubmit = () => {
    let formIsValid = true;
    const errorsCopy = { ...errors };

    // if (!serviceId) {
    //   errorsCopy.serviceId = "Please select an Option";
    //   formIsValid = false;
    // } else {
    //   errorsCopy.serviceId = "";
    // }

    if (!title) {
      errorsCopy.title = "Work Title is required";
      formIsValid = false;
    } else {
      errorsCopy.title = "";
    }

    if (!description) {
      errorsCopy.description = "Description is required";
      formIsValid = false;
    } else {
      errorsCopy.description = "";
    }

    if (!metaDescription) {
      errorsCopy.metaDescription = "Work Description is required";
      formIsValid = false;
    } else {
      errorsCopy.metaDescription = "";
    }

    if (!image) {
      errorsCopy.image = "Image is required";
      formIsValid = false;
    } else {
      errorsCopy.image = "";
    }

    setErrors(errorsCopy);

    if (formIsValid) {
      const payload = {
        serviceId,
        metaDescription,
        metaTitle,
        slugUrl,
        title,
        metaKeyword,
      };

      if (blogId === undefined) {
        addBlogData(payload)
          .then((res) => {
            navigate(`/blogs/blog-form`, {
              state: {
                id: res?.data?.id,
                name: res?.data?.title,
              },
            });
            setFormData(initialState);
          })
          .catch((err) => console.log("Create Blog Page From Add", err));
      } else {
        updateBlogs(blogId, payload)
          .then((res) => {
            navigate(`/blogs/blog-form`, {
              state: {
                id: blogId,
                name: formData?.title,
              },
            });
          })
          .catch((err) => console.log("Create Blog Page From Update", err));
      }
    }
  };

  const [clicked, setClicked] = useState({
    serviceId: false,
    title: false,
    slugUrl: false,
    metaDescription: false,
    metaTitle: false,
    metaKeyword: false,
    image: false,
  });

  const handleInputFocus = (fieldName) => {
    setClicked({ ...clicked, [fieldName]: true });

    setErrors({ ...errors, [fieldName]: "" });
  };

  const getServices = () => {
    getAllServices().then((res) => {
      setOptions(
        res?.data?.map((services) => {
          return {
            label: services?.serviceName,
            value: services?.id,
          };
        })
      );
    });
  };

  const { serviceId, metaDescription, metaTitle, slugUrl, title, metaKeyword, image } =
    formData;
  const renderCategoryForm = () => {
    return (
      <CCard>
        <CCardHeader><strong>Work Form</strong></CCardHeader>
        <CCardBody>
          {/* <Container> */}
          <CForm>
            <CRow className="align-items-start mb-2">
              <CCol xs={12} md={6} lg={6}>
              <CFormLabel>Work Title</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  name="title"
                  value={title}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("title")}
                  aria-describedby="exampleFormControlInputHelpInline"
                />
                {errors.title && (
                  <div className="text-danger">{errors.title}</div>
                )}
              </CCol>

              <CCol xs={12} md={6} lg={6} className="mb-2">
              <CFormLabel>Image</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormInput
                  type="file"
                  id="exampleFormControlInput1"
                  name="image"
                  value={image}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("image")}
                  aria-describedby="exampleFormControlInputHelpInline"
                />
                {errors.image && (
                  <div className="text-danger">{errors.image}</div>
                )}
              </CCol>
            </CRow>

            <CRow className="align-items-start mb-4">
              <CCol>                
                <CFormLabel>Description</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onChange={handleEditorChange}
                  name={description}
                  onFocus={() => handleInputFocus("description")}
                />
                {errors.description && (
                  <div className="text-danger">{errors.description}</div>
                )}
              </CCol>
            </CRow>
            <div className="btn-alg">
              <CButton onClick={handleSubmit}>Submit</CButton>
            </div>
          </CForm>
          {/* </Container> */}
        </CCardBody>
      </CCard>
    );
  };

  return renderCategoryForm();
};

export default AddWorkForm;
