import React, { useEffect, useState } from "react";
import { CFormInput, CForm, CButton, CCard, CCardBody, CCardHeader, CRow, CCol, CFormSelect, CFormLabel, CFormTextarea } from "@coreui/react";
import { useNavigate, useParams } from "react-router-dom";
import { getAllServices } from "src/api/service";
import { addBlogData, getBlogById, updateBlogs } from "src/api/blog";
import { getAllBlogCategory } from "src/api/blogCategories";
import { getAllBlogSubCategory } from "src/api/blogSubCategories";

const initialState = {
  serviceId: "",
  categoryId: "",
  authorName: "",
  metaDescription: "",
  metaTitle: "",
  slugUrl: "",
  title: "",
  metaKeyword: "",
};

const ManageBlogs = () => {

  let { id } = useParams();
  const blogPageID = id;
  const isUpdate = true;

  const navigate = useNavigate();
  const [formData, setFormData] = useState({ initialState });
  const [options, setOptions] = useState();
  const [blogList, setBlogList] = useState();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isUpdate) { getBlogsData() }
  }, [isUpdate]);

  useEffect(() => {
    getAllServicesForBlog();
    getAllCategory();
  }, []);

  const [clicked, setClicked] = useState({
    serviceId: false,
    categoryId: false,
    authorName: false,
    title: false,
    subtitle: false,
    slugUrl: false,
    metaDescription: false,
    metaTitle: false,
    metaKeyword: false,
    image: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const getBlogsData = () => {
    getBlogById(blogPageID)
      .then((res) => setFormData(res?.data))
      .catch((err) => console.log("Create Service Page From Get", err));
  };

  const handleSubmit = () => {


    let formIsValid = true;
    const errorsCopy = { ...errors };
    const { title, serviceId, categoryId, authorName, slugUrl, metaKeyword, metaDescription, metaTitle } = formData;
    if (!title) {
      errorsCopy.title = "Title is required";
      formIsValid = false;
    } else {
      errorsCopy.title = "";
    }

    if (!serviceId) {
      errorsCopy.serviceId = "Service ID is required";
      formIsValid = false;
    } else {
      errorsCopy.serviceId = "";
    }

    if (!categoryId) {
      errorsCopy.categoryId = "Category ID is required dsf";
      formIsValid = false;
    } else {
      errorsCopy.categoryId = "";
    }

    if (!authorName) {
      errorsCopy.authorName = "Author Name ID is required";
      formIsValid = false;
    } else {
      errorsCopy.authorName = "";
    }

    if (!slugUrl) {
      errorsCopy.slugUrl = "Slug-URL is required";
      formIsValid = false;
    } else {
      errorsCopy.slugUrl = "";
    }

    if (!metaKeyword) {
      errorsCopy.metaKeyword = "Meta Keyword is required";
      formIsValid = false;
    } else {
      errorsCopy.metaKeyword = "";
    }

    if (!metaDescription) {
      errorsCopy.metaDescription = "Work Description is required";
      formIsValid = false;
    } else {
      errorsCopy.metaDescription = "";
    }

    if (!metaTitle) {
      errorsCopy.metaTitle = "Meta Title is required";
      formIsValid = false;
    } else {
      errorsCopy.metaTitle = "";
    }

    setErrors(errorsCopy);

    if (formIsValid) {
      const payload = {
        serviceId,
        categoryId,
        authorName,
        metaDescription,
        metaTitle,
        slugUrl,
        title,
        metaKeyword,
      };

      if (blogPageID === undefined) {
        addBlogData(payload)
          .then((res) => {
            navigate(`/blogs/blog-form`, {
              state: {
                id: res?.data?.id,
                name: res?.data?.title,
              },
            });
            setFormData(initialState);
          })
          .catch((err) => console.log("Create Blog Page From Add", err));
      } else {
        updateBlogs(blogPageID, payload)
          .then((res) => {
            navigate(`/blogs/blog-form`, {
              state: {
                id: blogPageID,
                name: formData?.title,
              },
            });
          })
          .catch((err) => console.log("Create Blog Page From Update", err));
      }
    }
  };

  const handleInputFocus = (fieldName) => {
    setClicked({ ...clicked, [fieldName]: true });
    setErrors({ ...errors, [fieldName]: "" });
  };

  const getAllServicesForBlog = () => {
    getAllServices().then((res) => {
      setOptions(
        res?.data?.map((services) => {
          return {
            label: services?.serviceName,
            value: services?.id,
          };
        })
      );
    });
  };

  const getAllCategory = () => {
    getAllBlogSubCategory()
      .then((res) => setBlogList(res?.data))
      .catch((err) => alert("err", err));
  };

  const { serviceId, categoryId, authorName, metaDescription, metaTitle, slugUrl, title, metaKeyword } = formData;

  return (
    <CCard className="mb-4">
      <CCardHeader className="form-header">Create Blogs here</CCardHeader>
      <CCardBody>
        <CForm>
          <CRow>
            {/* old code  */}
            <CCol xs={12} md={6} lg={6} className="marMobile">
              <CFormLabel>Select Service</CFormLabel>
              <span className="red-star">{` `}*</span>
              <CFormSelect
                aria-label="Select Service"
                name="serviceId"
                value={serviceId}
                onChange={handleInputChange}
                onFocus={() => handleInputFocus("serviceId")}
              >
                <option value="" disabled>
                  Select Service
                </option>
                {options?.map((optionData, index) => {
                  return (
                    <option key={index} value={optionData?.value}>
                      {optionData?.label}
                    </option>
                  );
                })}
              </CFormSelect>
              {errors.serviceId && (
                <div className="text-danger">{errors.serviceId}</div>
              )}
            </CCol>

            <CCol xs={12} md={6} lg={6} className="marMobile">
              <CFormLabel htmlFor="categoryId">Blog Sub Category</CFormLabel>
              <span className="red-star">{` `}*</span>
              <CFormSelect
                id="categoryId"
                aria-label="Select Blog Category"
                name="categoryId"
                value={categoryId}
                onChange={handleInputChange}
                onFocus={() => handleInputFocus("categoryId")}
              >
                <option value="">
                  Select Blog Category
                </option>
                {blogList?.map((item, index) => {
                  return (
                    <option key={index} value={item?.id}>
                      {item?.title}
                    </option>
                  );
                })}
              </CFormSelect>
              {errors.categoryId && (
                <div className="text-danger">{errors.categoryId}</div>
              )}
            </CCol>
          </CRow>
          <br />
          <CRow>
            <CCol xs={12} md={6} lg={6}>
              <CFormLabel htmlFor="title">Blog Title</CFormLabel>
              <span className="red-star">{` `}*</span>
              <CFormInput
                type="text"
                id="title"
                placeholder="Enter Blog Title"
                name="title"
                value={title}
                onChange={handleInputChange}
                onFocus={() => handleInputFocus("title")}
                aria-describedby="exampleFormControlInputHelpInline"
                required
              />
              {errors.title && (
                <div className="text-danger">{errors.title}</div>
              )}
            </CCol>
            <CCol xs={12} md={6} lg={6}>
              <CFormLabel htmlFor="authorName">Author Name</CFormLabel>
              <span className="red-star">{` `}*</span>
              <CFormInput
                type="text"
                id="authorName"
                placeholder="Enter Author Name"
                name="authorName"
                value={authorName}
                onChange={handleInputChange}
                onFocus={() => handleInputFocus("authorName")}
                aria-describedby="exampleFormControlInputHelpInline"
                required
              />
              {errors.authorName && (
                <div className="text-danger">{errors.authorName}</div>
              )}
            </CCol>
          </CRow>
          <br />
          <CRow className="align-items-start">
            <CCol xs={12} md={6} lg={6} className="marMobile">
              <CFormLabel>Slug URL</CFormLabel>
              <span className="red-star">{` `}*</span>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                placeholder="Enter Slug URL"
                name="slugUrl"
                value={slugUrl}
                onChange={handleInputChange}
                onFocus={() => handleInputFocus("slugUrl")}
                aria-describedby="exampleFormControlInputHelpInline"
                required
              />
              {errors.slugUrl && (
                <div className="text-danger">{errors.slugUrl}</div>
              )}
            </CCol>
            <CCol xs={12} md={6} lg={6} className="marMobile">
              <CFormLabel>Meta Title</CFormLabel>
              <span className="red-star">{` `}*</span>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                placeholder="Enter Meta Title"
                name="metaTitle"
                value={metaTitle}
                onChange={handleInputChange}
                onFocus={() => handleInputFocus("metaTitle")}
                aria-describedby="exampleFormControlInputHelpInline"
              />
              {errors.metaTitle && (
                <div className="text-danger">{errors.metaTitle}</div>
              )}
            </CCol>

          </CRow>
          <br />
          <CRow className="align-items-start">
            <CCol xs={12} md={12} lg={12} className="mb-2">
              <CFormLabel>Meta Keywords</CFormLabel>
              <span className="red-star">{` `}*</span>
              <CFormTextarea
                type="message"
                id="exampleFormControlInput1"
                placeholder="Enter Meta Keyboards"
                name="metaKeyword"
                value={metaKeyword}
                onChange={handleInputChange}
                onFocus={() => handleInputFocus("metaKeyword")}
                aria-describedby="exampleFormControlInputHelpInline"
                row="3"
              />
              {errors.metaKeyword && (
                <div className="text-danger">{errors.metaKeyword}</div>
              )}
            </CCol>
          </CRow>
          <br />
          <CRow className="align-items-start">
            <CCol xs={12} md={12} lg={12} className="marMobile mb-4">
              <CFormLabel>Meta Description</CFormLabel>
              <span className="red-star">{` `}*</span>
              <CFormTextarea
                type="textarea"
                id="metaDescription"
                placeholder="Enter Meta Description"
                name="metaDescription"
                value={metaDescription}
                onChange={handleInputChange}
                onFocus={() => handleInputFocus("metaDescription")}
                aria-describedby="exampleFormControlInputHelpInline"
                rows="3"
              />
              {errors.metaDescription && (
                <div className="text-danger">{errors.metaDescription}</div>
              )}
            </CCol>
          </CRow>
          <CButton onClick={handleSubmit}>Submit</CButton>
        </CForm>
        {/* </Container> */}
      </CCardBody>
    </CCard>
  );
  // };

  // return renderCategoryForm();
};

export default ManageBlogs;
