import { API_BASE_URL } from "src/services/const";
import axiosInstance from "./axiosApi";

export const addBlogCategory = async (data) => {
    const res = await axiosInstance.post(`${API_BASE_URL}/blog-page-categories`, data);
    return res.data;
};

export const getAllBlogCategory = async () => {
    const res = await axiosInstance.get(`${API_BASE_URL}/blog-page-categories`);
    return res.data;
};

export const getSingleCategory = async (id) => {
    const res = await axiosInstance.get(`${API_BASE_URL}/blog-page-categories/${id}`);
    return res.data;
};

export const updateBlogCategory = async ({ id, data }) => {
    const res = await axiosInstance.put(`${API_BASE_URL}/blog-page-categories/${id}`, data);
    return res.data;
};

export const deleteBlogCategory = async ({ id, data }) => {
    const res = await axiosInstance.delete(`${API_BASE_URL}/blog-page-categories/${id}`, data);
    return res.data;
};