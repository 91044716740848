import request from "src/services/request";
import { API_BASE_URL } from "src/services/const";
import axiosInstance from "./axiosApi";


//Sub Category Section
export const addSubCategories = (data) =>
    request(`${API_BASE_URL}/blog-page-sub-categories`, {
        method: "POST",
        data,
    });

export const getSingleSubCategories = (id) => {
    return request(`${API_BASE_URL}/blog-page-sub-categories/${id}`, {
        method: "GET",
    });
};

export const updateSubCategory = (id, data) => {
    return request(`${API_BASE_URL}/blog-page-sub-categories/${id}`, {
        method: "PATCH",
        data,
    });
};
export const deleteSubCategory = (id) => {
    return request(`${API_BASE_URL}/blog-page-sub-categories/${id}`, {
        method: "DELETE",
    });
};

export const getAllBlogSubCategory = async () => {
    const res = await axiosInstance.get(`${API_BASE_URL}/blog-page-sub-categories`);
    return res.data;
};