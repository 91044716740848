import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CForm, CButton, CCard, CCardBody, CCardHeader, CRow, CCol, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow, CFormLabel } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Tooltip from "src/components/Tooltip";
import DeletePopMsg from "src/components/modal/DeletePopMsg";
import { addTabs, getAllTabs, getSingleTabs, updateTab, deleteTab } from "src/api/blog";
import axios from "axios";
import { uploadTextEditorImage } from "src/api/service";

const TabsPage = () => {
  const router = useLocation();
  const tabs = {
    blogPageId: router?.state?.id,
    image: "",
    description: "",
    title: "",
  };

  const [tabsAction, setTabsAction] = useState("create");
  const [tabsId, setTabsId] = useState("");
  const [tabSection, setTabSection] = useState(tabs);
  const [tabData, setTabData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [description, setdescription] = useState("")

  const ref = useRef(null);
  const blogTabImageRef = useRef()

  useEffect(() => {
    getAllTab();
  }, []);

  const handleTab = (e) => {
    const { name, value } = e.target;
    setTabSection({ ...tabSection, [name]: value });
  };

  //tabs submit
  const handleTabsSection = () => {
    const formData = new FormData();
    formData.append("blogPageId", tabSection.blogPageId)
    formData.append("description", description)
    formData.append("image", blogTabImageRef?.current?.files[0])
    formData.append("title", tabSection.title)
    if (tabsAction === "create") {
      addTabs(formData)
        .then((res) => {
          getAllTab();
          setdescription("")
          setTabSection(tabs);
          setTabsAction("create");
        })
        .catch((err) => console.log("err", err));
    } else {
      updateTab(tabsId, formData)
        .then((res) => {
          getAllTab();
          setdescription("")
          setTabSection(tabs);
          setTabsAction("create");
        })
        .catch((err) => console.log("err", err));
    }
  };

  //getSingleTabs
  const getSingleTab = (id) => {
    setTabSection({ ...tabSection, blogPageId: id })
    getSingleTabs(id)
      .then((res) => {
        setTabSection(res.data);
        setdescription(res.data.description)
      })
      .catch((err) => console.log("err", err));
  };

  //get All tabs
  const getAllTab = () => {
    getAllTabs()
      .then((res) => {
        setTabData(res?.data);
      })
      .catch((err) => console.log("err", err));
  };
  //delete Tabs
  const deleteTabs = (id) => {
    deleteTab(id)
      .then((res) => {
        getAllTab();
        setVisible(false);
      })
      .catch((err) => console.log("err", err));
  };

  const scrollElement = () => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setdescription(data);
  };
  // const editorConfiguration = {
  //   extraPlugins: [MyCustomUploadAdapterPlugin] // Register the custom plugin
  // };
  const editorConfig = {
    toolbar: {
      items: [
        'heading', // This is the heading dropdown
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        'blockQuote',
        'insertImage', // Insert Image option
        'insertTable', // Insert Table option
        'insertMedia', // Insert Media option
        'fontColor', // Font Color option
        '|',
        'undo',
        'redo',
        '|',
        'fontSize', // Font Size option
      ],
      shouldNotGroupWhenFull: true // Allows adding items to the toolbar without grouping
    },
    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' }, // Custom heading option
        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }, // Custom heading option
        { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' } // Custom heading option
      ]
    },
    fontSize: {
      options: ['tiny', 'small', 'big', 'default', 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40]
    }
  };

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            const formData = new FormData();
            formData.append("file", file);
            uploadTextEditorImage(formData)
              .then((response) => {
                resolve({ default: response.data.data.Location });
              })
              .catch((error) => {
                console.error("Error uploading image:", error);
                reject(error);
              });
          });
        });
      },
    };
  };




  const combinedEditorConfig = {
    ...editorConfig,
    extraPlugins: [MyCustomUploadAdapterPlugin], // Add custom upload adapter plugin
  };

  return (
    <CCard>
      <CCardBody>
        <CTable responsive className="table table-bordered">
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">S.No.</CTableHeaderCell>
              <CTableHeaderCell scope="col">Description</CTableHeaderCell>
              <CTableHeaderCell scope="col">Action</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {tabData?.length > 0 &&
              tabData.filter(item => item.blogPageId === parseInt(router?.state?.id))
                .map((item, index) => {
                  return (
                    <CTableRow key={index}>
                      <CTableDataCell>{index + 1}</CTableDataCell>
                      <CTableDataCell>
                        <div dangerouslySetInnerHTML={{
                          __html: item?.description && item.description.split(' ').slice(0, 11).join(' ') + (item.description.split(' ').length > 10 ? ' .....' : '')

                        }} />
                      </CTableDataCell>
                      <CTableDataCell>
                        <>
                          <Tooltip text="Update">
                            <FontAwesomeIcon
                              role="button"
                              icon={faPenToSquare}
                              size="1x"
                              className="px-3 updateIcon"
                              onClick={() => {
                                setTabsAction("edit");
                                setTabsId(item?.id);
                                getSingleTab(item?.id);
                                scrollElement();
                              }}
                            />
                          </Tooltip>

                          <Tooltip text="Delete">
                            <FontAwesomeIcon
                              onClick={() => {
                                setTabsAction("create");
                                setTabsId(item?.id);
                                setVisible(true);
                              }}
                              role="button"
                              icon={faTrash}
                              size="1x"
                              className="deleteIcon"
                            />
                          </Tooltip>
                        </>
                      </CTableDataCell>
                    </CTableRow>
                  );
                })}

            {/* ))} */}
          </CTableBody>
        </CTable>
      </CCardBody>
      <br />
      <CCardHeader ref={ref}>
        {tabsAction === "create"
          ? "Create Tab"
          : tabsAction === "edit"
            ? "Edit Tab"
            : ""}
      </CCardHeader>
      <CCardBody>
        <CForm>
          <CRow className="align-items-start">
            <CCol xs={12} md={6} lg={6}>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                label="Title"
                name="title"
                value={tabSection.title}
                onChange={handleTab}
                aria-describedby="exampleFormControlInputHelpInline"
              />
            </CCol>
            <CCol xs={12} md={6} lg={6}>
              <CFormInput
                type="file"
                id="exampleFormControlInput1"
                label="Image"
                ref={blogTabImageRef}
                onChange={handleTab}
                aria-describedby="exampleFormControlInputHelpInline"
              />
            </CCol>
          </CRow>
          <br />
          <CRow>
            <CCol>
              <CFormLabel>Description</CFormLabel>
              <CKEditor
                editor={ClassicEditor}
                data={description}
                onChange={handleEditorChange}
                // config={editorConfiguration}
                config={combinedEditorConfig}
              />
            </CCol>
          </CRow>
          <br />
          <CButton onClick={handleTabsSection}>Submit</CButton>
        </CForm>
      </CCardBody>

      <DeletePopMsg
        visible={visible}
        setVisible={setVisible}
        id={tabsId}
        deleteFunction={deleteTabs}
      />
    </CCard>
  );
}

export default TabsPage