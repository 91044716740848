import React, { useEffect, useRef, useState } from "react";
import {
  CFormInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CFormLabel,
} from "@coreui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAllServices, uploadTextEditorImage } from "src/api/service";
import { addBlogData, addHeroSection, getBlogById, updateBlogs } from "src/api/blog";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addhome_Hero, viewHeroData } from "src/api/heroApi";
import axios from "axios";

const HeroForm = () => {
  const { id } = useParams();
  const heroPageID = id;
  const isUpdate = true;

  const initialState = {
    title: "",
    sub_title: "",
    image: "",
    description: "",
  };

  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialState);
  const [options, setOptions] = useState();
  const [description, setdescription] = useState("");

  const imageRef = useRef();

  useEffect(() => {
    if (isUpdate) {
      viewHeroDataByID();
    }
  }, [isUpdate]);

  useEffect(() => {
    getServices();
  }, []);

  const viewHeroDataByID = () => {
    viewHeroData(heroPageID, formData)
      .then((res) => {
        setFormData(res?.data);
        setdescription(res?.data?.description);
      })
      .catch((err) => console.log("error", err));
  }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setdescription(data);
    // setTabSection({...tabSection,description:data})
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const [errors, setErrors] = useState({});


  const handleSubmit = () => {
    let formIsValid = true;
    const errorsCopy = { ...errors };

    if (!title) {
      errorsCopy.title = "Title is required";
      formIsValid = false;
    } else {
      errorsCopy.title = "";
    }

    if (!sub_title) {
      errorsCopy.sub_title = "Sub-Title is required";
      formIsValid = false;
    } else {
      errorsCopy.sub_title = "";
    }

    if (!description) {
      errorsCopy.description = "Description is required";
      formIsValid = false;
    } else {
      errorsCopy.description = "";
    }

    if (!image) {
      errorsCopy.image = "Image is required";
      formIsValid = false;
    } else {
      errorsCopy.image = "";
    }

    setErrors(errorsCopy);

    if (formIsValid) {

      const formData = new FormData();
      formData.append("title", title);
      formData.append("sub_title", sub_title);
      formData.append("image", imageRef?.current?.files[0]);
      formData.append("description", description);


      if (heroPageID === undefined) {
        addhome_Hero(formData)
          .then((res) => {
            setFormData(initialState);
            setdescription("")
            navigate("/home/hero-section");
          })
          .catch((err) => console.log("Create hero Page From Add", err));
      }
    }
  };
  const editorConfiguration = {
    extraPlugins: [MyCustomUploadAdapterPlugin] // Register the custom plugin
  };

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            const formData = new FormData();
            formData.append("file", file);
            uploadTextEditorImage(formData)
              .then((response) => {
                resolve({ default: response.data.data.Location });
              })
              .catch((error) => {
                console.error("Error uploading image:", error);
                reject(error);
              });
          });
        });
      },
    };
  };


  const [clicked, setClicked] = useState({
    serviceId: false,
    title: false,
    sub_title: false,
    metaDescription: false,
    metaTitle: false,
    metaKeyword: false,
    image: false,
  });

  const handleInputFocus = (fieldName) => {
    setClicked({ ...clicked, [fieldName]: true });

    setErrors({ ...errors, [fieldName]: "" });
  };

  const getServices = () => {
    getAllServices().then((res) => {
      setOptions(
        res?.data?.map((services) => {
          return {
            label: services?.serviceName,
            value: services?.id,
          };
        })
      );
    });
  };

  const { title, sub_title, image } =
    formData;
  const renderCategoryForm = () => {
    return (
      <CCard>
        <CCardHeader><strong>Add Hero Section Details</strong></CCardHeader>
        <CCardBody>
          {/* <Container> */}
          <CForm>
            <CRow className="align-items-start">
              <CCol xs={12} md={6} lg={6}>
                <CFormLabel>Title</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  name="title"
                  value={title}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("title")}
                  aria-describedby="exampleFormControlInputHelpInline"
                />
                {errors.title && (
                  <div className="text-danger">{errors.title}</div>
                )}
              </CCol>

              <CCol xs={12} md={6} lg={6} className="mb-2">
                <CFormLabel>Sub-Title</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  name="sub_title"
                  value={sub_title}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("sub_title")}
                  aria-describedby="exampleFormControlInputHelpInline"
                />
                {errors.sub_title && (
                  <div className="text-danger">{errors.sub_title}</div>
                )}
              </CCol>
            </CRow>

            <CRow className="align-items-start">
              <CCol xs={12} md={6} lg={6} className="mb-2">
                <CFormLabel>Image</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormInput
                  type="file"
                  id="exampleFormControlInput1"
                  name="image"
                  // value={image}
                  ref={imageRef}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("image")}
                  aria-describedby="exampleFormControlInputHelpInline"
                />
                {image && <p>Current image: {image}</p>}

                {errors.image && (
                  <div className="text-danger">{errors.image}</div>
                )}
              </CCol>
            </CRow>

            <CRow className="align-items-start mb-4">
              <CCol>
                <CFormLabel>Description</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onChange={handleEditorChange}
                  name={description}
                  config={editorConfiguration}
                  onFocus={() => handleInputFocus("description")}
                //   config={{
                //   image: {
                //     uploadAdapter: uploadAdapter,
                //   },
                // }}
                />
                {errors.description && (
                  <div className="text-danger">{errors.description}</div>
                )}
              </CCol>
            </CRow>

            <div className="btn-alg">
              <CButton onClick={handleSubmit}>Submit</CButton>
            </div>
          </CForm>
          {/* </Container> */}
        </CCardBody>
      </CCard>
    );
  };

  return renderCategoryForm();
};

export default HeroForm;

