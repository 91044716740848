import React, { useEffect, useState } from "react";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
  CCard,
  CCardHeader,
  CCardBody,
  CFormInput,
  CFormSelect,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import { Link } from "react-router-dom";
import { deleteBlogs } from "src/api/blog";
import ReactPaginate from "react-js-pagination";
import { getHeroSection } from "src/api/service";
import { deleteHeroData, gethome_Hero } from "src/api/heroApi";
import DeletePopMsg from "src/components/modal/DeletePopMsg";

// import DeletePopMsg from "src/components/modal/DeletePopMsg";

export default function HeroSection(data) {
  const [dataHeroSection, setDataHeroSection] = useState([]);
  const [visible, setVisible] = useState(false);
  const [blogId, setBlogId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [heroID, setHeroID] = useState("");

  useEffect(() => {
    getHeroSectionData();
  }, []);

  const getHeroSectionData = () => {
    gethome_Hero()
      .then(res => {
        setDataHeroSection(res?.data)
      })
      .catch(err => alert(err))
  };

  const deleteHero = () => {
    deleteHeroData(heroID)
      .then((res) => {
        setVisible(false);
      })
      .catch((err) => console.log("Error Hero Section Deletion", err));
  };

  const filteredData = dataHeroSection.filter((item) =>
    item.title.toLowerCase().includes(searchInput.toLowerCase())
  );

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <>
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Hero Section</strong>
        </CCardHeader>
        <div className="search_bar">
          <div className="search-inner">
            {/* {showSelectAndSearch && ( */}
            <div>
              <CFormSelect
                size="sm"
                className="mb-3"
                style={{ cursor: "pointer" }}
                aria-label="Small select example"
                onChange={(e) => setRowsPerPage(e.target.value)}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </CFormSelect>
            </div>
            {/* )} */}
            {/* search div */}
            <div style={{ display: "flex" }}>
              {/* {showSelectAndSearch && ( */}
              <>
                <CFormInput
                  type="text"
                  size="sm"
                  placeholder="Search"
                  aria-label="sm input example"
                  style={{ margin: "0px 0px 16px 0px" }}
                  onChange={handleSearchChange}
                  value={searchInput}
                />
                <span
                  style={{
                    border: " 1px solid #d1c5c5",
                    borderRadius: "4px",
                    margin: "0px 5px 16px -1px",
                    padding: "1px 8px 0px 8px",
                    background: "#ebedef",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    role="button"
                    icon={faSearch}
                    size="1x"
                  />
                </span>
              </>
              {/* )} */}
            </div>
            <div>
              <Link to="/heroform" state={{ isUpdate: false }}>
                <CButton className="float-end btn-addbtn">Add</CButton>
              </Link>
            </div>
          </div>


        </div>
        <CCardBody className="pb-0 mb-2">

          <CTable responsive striped hover className="table table-bordered custom-table-border row-hover">
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell className="purpleRow col-md-1" scope="col">S.No.</CTableHeaderCell>
                <CTableHeaderCell className="purpleRow col-md-2" scope="col">Title</CTableHeaderCell>
                <CTableHeaderCell className="purpleRow col-md-3" scope="col">Sub-Title</CTableHeaderCell>
                <CTableHeaderCell className="purpleRow col-md-3" scope="col">Description</CTableHeaderCell>
                <CTableHeaderCell className="purpleRow col-md-1" scope="col">Action</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {currentRows?.length > 0 && (
                currentRows
                  ?.filter((item) =>
                    item?.title
                      .toLowerCase()
                      .includes(searchInput.toLowerCase())
                  ))?.map((item, index) => {
                    return (
                      <CTableRow key={index}>
                        <CTableDataCell>{index + 1}</CTableDataCell>
                        <CTableDataCell>{item.title || "-"}</CTableDataCell>
                        <CTableDataCell>{item.sub_title || "-"}</CTableDataCell>
                        <CTableDataCell>{item.description.length > 85 ? item.description.substring(0, 85) + "..." : item.description || "-"}</CTableDataCell>
                        <CTableDataCell>
                          <>
                            <Tooltip text="View">
                              <Link
                                to="/viewheroform"
                                state={{ heroId: item.id, isView: true }}
                              >
                                <FontAwesomeIcon
                                  role="button"
                                  icon={faEye}
                                  size="1x"
                                  className="viewIcon"
                                />
                              </Link>
                            </Tooltip>

                            <Tooltip text="Update">
                              <Link
                                to={`/heroform/${item.id}`}
                                state={{ heroId: item.id, isUpdate: true }}
                              >
                                <FontAwesomeIcon
                                  role="button"
                                  icon={faPenToSquare}
                                  size="1x"
                                  className="px-3 updateIcon"
                                />
                              </Link>
                            </Tooltip>

                            <Tooltip text="Delete">
                              <FontAwesomeIcon
                                role="button"
                                icon={faTrash}
                                size="1x"
                                className="deleteIcon"
                                onClick={() => {
                                  setHeroID(item?.id);
                                  setVisible(true);
                                }}
                              />
                            </Tooltip>
                          </>
                        </CTableDataCell>
                      </CTableRow>
                    );
                  })}


            </CTableBody>
          </CTable>
          <div className="pagin-div">
            <div>
              {/* Showing {indexOfFirstRow + 1} to{" "}
                    {Math?.min(indexOfLastRow, data?.blogData?.data?.length)} of{" "}
                    {data?.blogData?.data?.length} entries */}
              {/* dummyData */}
              Showing {indexOfFirstRow + 1} to{" "}
              {Math?.min(indexOfLastRow, dataHeroSection?.length)} of{" "}
              {dataHeroSection?.length} entries
            </div>
            <div className="pagination-container">
              <ReactPaginate
                activePage={currentPage}
                itemsCountPerPage={rowsPerPage}
                totalItemsCount={dataHeroSection?.length}
                pageRangeDisplayed={5}
                //   onChange={handlePageChange}
                prevPageText="Previous"
                nextPageText="Next"
                firstPageText="First"
                lastPageText="Last"
                innerClass="pagination"
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </div>
        </CCardBody>
        <DeletePopMsg
          visible={visible}
          setVisible={setVisible}
          id={heroID}
          deleteFunction={deleteHero}
        />
      </CCard>
    </>
  )
}

