import React from "react";
import { CDropdown, CDropdownToggle } from "@coreui/react";
import { cilAccountLogout } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { successAlert } from "src/utils/swalert";
import { useNavigate } from "react-router";

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('token');
    successAlert("You are Logged Out !", "Login to Enter Dashboard")
    navigate("/login");
  }

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CIcon onClick={handleLogout} icon={cilAccountLogout} className="me-2" />
        <span onClick={handleLogout}>Log Out</span>
      </CDropdownToggle>
    </CDropdown>
  );
};

export default ProfileDropdown;
