import React, { useEffect, useRef, useState } from "react";
import {
  CFormInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CFormLabel,
} from "@coreui/react";
import { useNavigate, useParams } from "react-router-dom";
import { addTeamMember, getServices, getSingleTestinomial, updateTestinomial, uploadTextEditorImage } from "src/api/service";
import { addBlogData, getBlogById, updateBlogs } from "src/api/blog";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";

const TestimonialsForm = () => {
  // const route = useLocation();
  // const { blogId, testimonialID, isUpdate } = route?.state;
  const { id } = useParams();
  const testimonialPageID = id;
  const isUpdate = true;
  const initialState = {
    name: "",
    designation: "",
    description: "",
    image: "",
    facebookLink: "",
    instagramLink: "",
    linkedinLink: "",
    twitterLink: "",
  };

  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialState);
  const [options, setOptions] = useState();
  const [description, setdescription] = useState("");

  // const [imageRef, setImageRef] = useState(useRef());

  const imageRef = useRef();

  useEffect(() => {
    if (isUpdate) {
      getSingleTestimonialData();
    }
  }, [isUpdate]);

  const getSingleTestimonialData = () => {
    getSingleTestinomial(testimonialPageID)
      .then((res) => {
        setFormData(res?.data);
        setdescription(res?.data?.description);
        // setImageRef(res?.data?.image)
      })
      .catch((err) => console.log("error in getSingleTestimonialData", err));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const [errors, setErrors] = useState({});

  const handleSubmit = () => {

    let formIsValid = true;
    const errorsCopy = { ...errors };

    if (!name) {
      errorsCopy.name = "Person Name is required";
      formIsValid = false;
    } else {
      errorsCopy.name = "";
    }

    if (!designation) {
      errorsCopy.designation = "Designation is required";
      formIsValid = false;
    } else {
      errorsCopy.designation = "";
    }

    if (!facebookLink) {
      errorsCopy.facebookLink = "Facebook Link is required";
      formIsValid = false;
    } else {
      errorsCopy.facebookLink = "";
    }

    if (!instagramLink) {
      errorsCopy.instagramLink = "Instagram Link is required";
      formIsValid = false;
    } else {
      errorsCopy.instagramLink = "";
    }

    if (!linkedinLink) {
      errorsCopy.linkedinLink = "Linkedin Link is required";
      formIsValid = false;
    } else {
      errorsCopy.linkedinLink = "";
    }

    if (!twitterLink) {
      errorsCopy.twitterLink = "Twitter Link is required";
      formIsValid = false;
    } else {
      errorsCopy.twitterLink = "";
    }

    if (!image) {
      errorsCopy.image = "Image is required";
      formIsValid = false;
    } else {
      errorsCopy.image = "";
    }

    if (!description) {
      errorsCopy.description = "Description is required";
      formIsValid = false;
    } else {
      errorsCopy.description = "";
    }

    setErrors(errorsCopy);

    if (formIsValid) {

      const payload = new FormData();
      payload.append("name", name);
      payload.append("designation", designation);
      payload.append("image", imageRef?.current?.files[0]);
      payload.append("description", description);
      payload.append("facebookLink", facebookLink);
      payload.append("instagramLink", instagramLink);
      payload.append("twitterLink", twitterLink);
      payload.append("linkedinLink", linkedinLink);

      if (testimonialPageID === undefined) {
        addTeamMember(payload)
          .then((res) => {
            setFormData(initialState);
            setdescription("");
            navigate("/testimonials");
          })
          .catch((err) => console.log("Error in Adding team member", err));
      }
      else {
        updateTestinomial(testimonialPageID, payload)
          .then((res) => {
            navigate(`/testimonials`, {

              // state: {
              //   id: blogId,
              //   name: formData?.title,
              // },
            });
          })
          .catch((err) => console.log("Create Blog Page From Update", err));
      }
    }
  };

  const [clicked, setClicked] = useState({
    name: false,
    designation: false,
    description: false,
    image: false,
    facebookLink: false,
    instagramLink: false,
    linkedinLink: false,
    twitterLink: false,
  });

  const handleInputFocus = (fieldName) => {
    setClicked({ ...clicked, [fieldName]: true });

    setErrors({ ...errors, [fieldName]: "" });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setdescription(data);
  };

  const editorConfiguration = {
    extraPlugins: [MyCustomUploadAdapterPlugin] // Register the custom plugin
  };

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            const formData = new FormData();
            formData.append("file", file);
            uploadTextEditorImage(formData)
              .then((response) => {
                resolve({ default: response.data.data.Location });
              })
              .catch((error) => {
                console.error("Error uploading image:", error);
                reject(error);
              });
          });
        });
      },
    };
  };

  // const { description} = formData;
  const { name, designation, createdAt, facebookLink, instagramLink, linkedinLink, twitterLink, image, updatedAt } = formData;
  const renderCategoryForm = () => {
    return (
      <CCard className="mb-4">
        <CCardHeader> <strong>Testimonial Form</strong></CCardHeader>
        <CCardBody>
          <CForm>
            <CRow className="align-items-start mb-2">
              <CCol xs={12} md={6} lg={6}>
                <CFormLabel>Person Name</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  name="name"
                  value={name}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus(name)}
                  aria-describedby="exampleFormControlInputHelpInline"
                />
                {errors.name && (
                  <div className="text-danger">{errors.name}</div>
                )}
              </CCol>

              <CCol xs={12} md={6} lg={6}>
                <CFormLabel>Designation</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  name="designation"
                  value={designation}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus(designation)}
                  aria-describedby="exampleFormControlInputHelpInline"
                />
                {errors.designation && (
                  <div className="text-danger">{errors.designation}</div>
                )}
              </CCol>
            </CRow>
            <CRow className="align-items-start mb-2">
              <CCol xs={12} md={6} lg={6}>
                <CFormLabel>Twitter Link</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  name="twitterLink"
                  value={twitterLink}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus(twitterLink)}
                  aria-describedby="exampleFormControlInputHelpInline"
                />
                {errors.twitterLink && (
                  <div className="text-danger">{errors.twitterLink}</div>
                )}
              </CCol>

              <CCol xs={12} md={6} lg={6}>
                <CFormLabel>Facebook Link</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  name="facebookLink"
                  value={facebookLink}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus(facebookLink)}
                  aria-describedby="exampleFormControlInputHelpInline"
                />
                {errors.facebookLink && (
                  <div className="text-danger">{errors.facebookLink}</div>
                )}
              </CCol>
            </CRow>

            <CRow className="align-items-start mb-2">
              <CCol xs={12} md={6} lg={6}>
                <CFormLabel>Instagram Link</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  name="instagramLink"
                  value={instagramLink}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus(instagramLink)}
                  aria-describedby="exampleFormControlInputHelpInline"
                />
                {errors.instagramLink && (
                  <div className="text-danger">{errors.instagramLink}</div>
                )}
              </CCol>

              <CCol xs={12} md={6} lg={6}>
                <CFormLabel>Linkedin Link</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  name="linkedinLink"
                  value={linkedinLink}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus(linkedinLink)}
                  aria-describedby="exampleFormControlInputHelpInline"
                />
                {errors.linkedinLink && (
                  <div className="text-danger">{errors.linkedinLink}</div>
                )}
              </CCol>
            </CRow>

            <CRow className="align-items-start mb-2">
              <CCol xs={12} md={6} lg={6} className="mb-2">
                <CFormLabel>Image</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormInput
                  type="file"
                  id="exampleFormControlInput1"
                  name="image"
                  ref={imageRef}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("image")}
                  aria-describedby="exampleFormControlInputHelpInline"
                />
                {image && <p>Current image: {image}</p>}
                {errors.image && (
                  <div className="text-danger">{errors.image}</div>
                )}
              </CCol>
            </CRow>


            <CRow className="align-items-start mb-4">
              <CCol>
                <CFormLabel>Description</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onChange={handleEditorChange}
                  name={description}
                  value={description}
                  onFocus={() => handleInputFocus("description")}
                  config={editorConfiguration}
                />
                {errors.description && (
                  <div className="text-danger">{errors.description}</div>
                )}
              </CCol>
            </CRow>

            <div className="btn-alg">
              <CButton onClick={handleSubmit}>Submit</CButton>
            </div>
          </CForm>
          {/* </Container> */}
        </CCardBody>
      </CCard>
    );
  };

  return renderCategoryForm();
};

export default TestimonialsForm;

