import React from "react";
import CIcon from "@coreui/icons-react";
import { cilSpeedometer } from "@coreui/icons";
import { CNavGroup, CNavItem } from "@coreui/react";

const Menu = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "Master",
    to: "/user",

    items: [
      {
        component: CNavItem,
        name: "Users",
        to: "/users",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Headers",
    to: "/headers",

    items: [
      {
        component: CNavItem,
        name: "Headers",
        to: "/headers",
      },
      {
        component: CNavItem,
        name: "Sub Menu",
        to: "/submenu",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Home Page",
    to: "/home",

    items: [
      {
        component: CNavItem,
        name: "Hero Section",
        to: "/home/hero-section",
      },
      // {
      //   component: CNavItem,
      //   name: "Works",
      //   to: "/ourworks",
      // },
      // {
      //   component: CNavItem,
      //   name: "Pricing",
      //   to: "/pricing",
      // },
      {
        component: CNavItem,
        name: "Testimonials",
        to: "/testimonials",
      },
    ],
  },

  {
    component: CNavGroup,
    name: "Services",
    to: "/services",

    items: [
      {
        component: CNavItem,
        name: "Services",
        to: "/services",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Blogs",
    to: "/blog",

    items: [
      {
        component: CNavItem,
        name: "Blogs",
        to: "/blogs",
      },
      {
        component: CNavItem,
        name: "Categories",
        to: "/blogcategory",
      },
      {
        component: CNavItem,
        name: "Sub Categories",
        to: "/blogsubcategory",
      }
    ],
  },

];

export default Menu;
