import React, { useEffect, useState } from "react";
import { getAllBlogs } from "src/api/blog";
// import BlogPage from "src/components/Blogs/BlogPage";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
  CCard,
  CCardHeader,
  CCardBody,
  CFormInput,
  CFormSelect,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import { Link } from "react-router-dom";
import { deleteBlogs } from "src/api/blog";
import ReactPaginate from "react-js-pagination";

import DeletePopMsg from "src/components/modal/DeletePopMsg";

const Blog = () => {
  const [allBlogs, setAllBlogs] = useState([]);
  const [visible, setVisible] = useState(false);
  const [blogId, setBlogId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    getAllBlog();
  }, []);

  const getAllBlog = () => {
    getAllBlogs()
      .then(res => setAllBlogs(res?.data))
      .catch(err => alert(err))
  };

  const deleteBlog = (id) => {
    deleteBlogs(id)
      .then((res) => {
        setVisible(false);
      })
      .catch((err) => alert("err", err));
  };

  const filteredBlogs = allBlogs.filter((blog) =>
    blog.title.toLowerCase().includes(searchInput.toLowerCase())
  );

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredBlogs.slice(indexOfFirstRow, indexOfLastRow);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <>
      <CCard className="mb-4">
        <CCardHeader className="form-header">
          Blog Page
        </CCardHeader>
        <div className="search_bar">
          <div className="search-inner">
            {/* {showSelectAndSearch && ( */}
            <div>
              <CFormSelect
                size="sm"
                className="mb-3"
                style={{ cursor: "pointer" }}
                aria-label="Small select example"
                onChange={(e) => setRowsPerPage(e.target.value)}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </CFormSelect>
            </div>
            {/* )} */}
            {/* search div */}
            <div style={{ display: "flex" }}>
              {/* {showSelectAndSearch && ( */}
              <>
                <CFormInput
                  type="text"
                  size="sm"
                  placeholder="Search"
                  aria-label="sm input example"
                  style={{ margin: "0px 0px 16px 0px" }}
                  onChange={handleSearchChange}
                  value={searchInput}
                />
                <span
                  style={{
                    border: " 1px solid #d1c5c5",
                    borderRadius: "4px",
                    margin: "0px 5px 16px -1px",
                    padding: "1px 8px 0px 8px",
                    background: "#ebedef",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    role="button"
                    icon={faSearch}
                    size="1x"
                  />
                </span>
              </>
              {/* )} */}
            </div>
            <div>
              <Link to="/blogs/add" state={{ isUpdate: false }}>
                <CButton className="float-end btn-addbtn">
                  Add
                </CButton>
              </Link>
            </div>
          </div>

        </div>
        <CCardBody className="pb-0">
          <CTable responsive striped hover className="table table-bordered custom-table-border row-hover">
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell className="col-1 purpleRow" scope="col">S.No.</CTableHeaderCell>
                <CTableHeaderCell className="col-2 purpleRow" scope="col">Title</CTableHeaderCell>
                <CTableHeaderCell className="col-3 purpleRow" scope="col">Meta Description</CTableHeaderCell>
                <CTableHeaderCell className="col-3 purpleRow" scope="col">Meta Title</CTableHeaderCell>
                <CTableHeaderCell className="col-2 purpleRow" scope="col">Slug URL</CTableHeaderCell>
                <CTableHeaderCell className="col-2 purpleRow" scope="col">Action</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {currentRows?.length > 0 && (
                currentRows
                  ?.filter((item) =>
                    item?.title
                      .toLowerCase()
                      .includes(searchInput.toLowerCase())
                  ))?.map((item, index) => {
                    return (
                      <CTableRow key={index}>
                        <CTableDataCell>{index + 1}</CTableDataCell>
                        <CTableDataCell>{item.title || "-"}</CTableDataCell>
                        <CTableDataCell>
                          {item.metaDescription.length > 65 ? item.metaDescription.substring(0, 65) + "..." : item.metaDescription || "-"}
                        </CTableDataCell>
                        <CTableDataCell>
                          {item.metaTitle.length > 65 ? item.metaTitle.substring(0, 65) + "..." : item.metaTitle || "-"}
                        </CTableDataCell>
                        <CTableDataCell>{item.slugUrl || "-"}</CTableDataCell>
                        <CTableDataCell>
                          <>
                            <Tooltip text="View">
                              <Link
                                to="/blogs/view"
                                state={{ blogId: item.id, isUpdate: true }}
                              >
                                <FontAwesomeIcon
                                  role="button"
                                  icon={faEye}
                                  size="1x"
                                  className="viewIcon"
                                />
                              </Link>
                            </Tooltip>

                            <Tooltip text="Update">
                              <Link
                                // to="/blogs/add"
                                to={`/blogs/add/${item.id}`}
                                state={{ blogId: item.id, isUpdate: true }}
                              >
                                <FontAwesomeIcon
                                  role="button"
                                  icon={faPenToSquare}
                                  size="1x"
                                  className="px-3 updateIcon"
                                />
                              </Link>
                            </Tooltip>

                            <Tooltip text="Delete">
                              <FontAwesomeIcon
                                role="button"
                                icon={faTrash}
                                size="1x"
                                className="deleteIcon"
                                onClick={() => {
                                  setBlogId(item?.id);
                                  setVisible(true);
                                }}
                              />
                            </Tooltip>
                          </>
                        </CTableDataCell>
                      </CTableRow>
                    );
                  })}
            </CTableBody>
          </CTable>
          <div className="pagin-div">
            <div>
              Showing {indexOfFirstRow + 1} to{" "}
              {Math?.min(indexOfLastRow, allBlogs?.length)} of{" "}
              {allBlogs.length} entries
            </div>
            <div className="pagination-container">
              <ReactPaginate
                activePage={currentPage}
                itemsCountPerPage={rowsPerPage}
                totalItemsCount={allBlogs.length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                prevPageText="Previous"
                nextPageText="Next"
                firstPageText="First"
                lastPageText="Last"
                innerClass="pagination"
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </div>
        </CCardBody>
        <DeletePopMsg
          visible={visible}
          setVisible={setVisible}
          id={blogId}
          deleteFunction={deleteBlog}
        />
      </CCard>
    </>
  );
};

export default Blog;
