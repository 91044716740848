import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getAllServices, getServicesById, } from "src/api/service";
import HeroSection from "src/components/Services/HeroSection";
import QueAns from "src/components/Services/QuesAns";
import Description from "src/components/Services/Description";
import GstSection from "src/components/Services/GstSection";
import SlugUrl from "src/components/Services/SlugUrl";


// new imports
// import PropTypes from "prop-types";
import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { Tab, Tabs } from "react-bootstrap";

const ServicePage = () => {

  const route = useLocation();

  const [serviceOptions, setServiceOptions] = useState([]);
  const [heroDatabyID, setHeroDatabyID] = useState([]);
  const [GSTDatabyID, setGSTDatabyID] = useState([]);
  const [descriptionDatabyID, setDescriptionDatabyID] = useState([]);
  const [questionDatabyID, setQuestionDatabyID] = useState([]);

  const [key, setKey] = useState("home");

  useEffect(() => {
    getAllServices()
      .then((res) => {
        setServiceOptions(
          res?.data?.map((services) => {
            return {
              label: services?.serviceName,
              value: services?.id,
            };
          })
        );
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  useEffect(() => {
    getServicesById(route.state.id)
      .then((res) => {
        setHeroDatabyID(res.data[0].herosection);
        setGSTDatabyID(res.data[0].gstregistrations);
        setDescriptionDatabyID(res.data[0].description);
        setQuestionDatabyID(res.data[0].questions);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);


  return (
    <>
      <CContainer>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="home" title="Hero Section">
            {HeroSection && <HeroSection heroDatabyID={heroDatabyID} />}
          </Tab>

          <Tab eventKey="gst" title="Video Tab Section">
            {GstSection && <GstSection GSTDatabyID={GSTDatabyID} />}
          </Tab>

          <Tab eventKey="desc" title="Main Description">
            {Description && <Description descriptionDatabyID={descriptionDatabyID} />}
          </Tab>

          <Tab eventKey="qa" title="Q & A Section">
            {QueAns && <QueAns questionDatabyID={questionDatabyID} />}
          </Tab>

          <Tab eventKey="slug" title="Select City or State Service Section">
            {SlugUrl && <SlugUrl GSTDatabyID={GSTDatabyID} />}
          </Tab>



        </Tabs>
      </CContainer>
      {/* </CModalBody>
      </CModal> */}
    </>
  );
};

export default ServicePage;
