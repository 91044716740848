// import { provider } from "../provider";
import React, { useEffect, useState } from "react";
import {
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton,
    CCard,
    CCardHeader,
    CCardBody,
    CFormInput,
    CFormSelect,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEye,
    faPenToSquare,
    faSearch,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import { Link } from "react-router-dom";
import { deleteBlogs } from "src/api/blog";
import ReactPaginate from "react-js-pagination";

import DeletePopMsg from "src/components/modal/DeletePopMsg";

import { getBlogs } from "src/api/blog";


export default function BlogPage(data) {
    const [visible, setVisible] = useState(false);
    const [blogId, setBlogId] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState("");

    const deleteBlog = (id) => {
        deleteBlogs(id)
            .then((res) => {
                data.setStateChange(!data.stateChange);

                setVisible(false);
            })
            .catch((err) => console.log("err", err));
    };

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = data?.blogData?.data?.slice(
        indexOfFirstRow,
        indexOfLastRow
    );

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    const blogData = [
        {
            title: "anjani creations",
            metaDescription: "anjani creations limited",
            metaTitle: "anjani creations",
            slugUrl: "anjanicreations.com"
        }
    ]

    return (
        <CCard>
            <CCardHeader>
                <strong>Blog List</strong>
                {/* <Link to="/blogs/blog/add" state={{ isUpdate: false }}>
                    <CButton className="float-end btn-addbtn">Add</CButton>
                </Link> */}
            </CCardHeader>
            <div className="search_bar">
                <div className="search-inner">
                    {/* {showSelectAndSearch && ( */}
                    <div>
                        <CFormSelect
                            size="sm"
                            className="mb-3"
                            style={{ cursor: "pointer" }}
                            aria-label="Small select example"
                            onChange={(e) => setRowsPerPage(e.target.value)}
                        >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                        </CFormSelect>
                    </div>
                    {/* )} */}
                    {/* search div */}
                    <div style={{ display: "flex" }}>
                        {/* {showSelectAndSearch && ( */}
                        <>
                            <CFormInput
                                type="text"
                                size="sm"
                                placeholder="Search"
                                aria-label="sm input example"
                                style={{ margin: "0px 0px 16px 0px" }}
                                onChange={handleSearchChange}
                                value={searchInput}
                            />
                            <span
                                style={{
                                    border: " 1px solid #d1c5c5",
                                    borderRadius: "4px",
                                    margin: "0px 5px 16px -1px",
                                    padding: "1px 8px 0px 8px",
                                    background: "#ebedef",
                                    cursor: "pointer",
                                }}
                            >
                                <FontAwesomeIcon
                                    role="button"
                                    icon={faSearch}
                                    size="1x"
                                />
                            </span>
                        </>
                        {/* )} */}
                    </div>
                </div>
            </div>
            <CCardBody>

                <CTable responsive className="table table-bordered">
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell className="col-1" scope="col">S.No.</CTableHeaderCell>
                            <CTableHeaderCell className="col-2" scope="col">Title</CTableHeaderCell>
                            <CTableHeaderCell className="col-3" scope="col">Meta Description</CTableHeaderCell>
                            <CTableHeaderCell className="col-3" scope="col">Meta Title</CTableHeaderCell>
                            <CTableHeaderCell className="col-2" scope="col">Slug URL</CTableHeaderCell>
                            {/* <CTableHeaderCell className="col-1" scope="col">Action</CTableHeaderCell> */}
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                        {blogData?.map((item, index) => (
                            <CTableRow key={index}>
                                {/* <CTableDataCell>{item.id || "-"}</CTableDataCell> */}
                                <CTableDataCell>{index + 1}</CTableDataCell>
                                <CTableDataCell>{item.title || "-"}</CTableDataCell>
                                <CTableDataCell>{item.metaDescription || "-"}</CTableDataCell>
                                <CTableDataCell>{item.metaTitle || "-"}</CTableDataCell>
                                <CTableDataCell>{item.slugUrl || "-"}</CTableDataCell>
                                {/* <CTableDataCell>
                                    <>
                                        <Tooltip text="View">
                                            <FontAwesomeIcon
                                                role="button"
                                                icon={faEye}
                                                size="1x"
                                                className="viewIcon"
                                            />
                                        </Tooltip>

                                        <Tooltip text="Update">
                                            <Link
                                                to="/blogs/blog/add"
                                                state={{ blogId: item.id, isUpdate: true }}
                                            >
                                                <FontAwesomeIcon
                                                    role="button"
                                                    icon={faPenToSquare}
                                                    size="1x"
                                                    className="px-3 updateIcon"
                                                />
                                            </Link>
                                        </Tooltip>

                                        <Tooltip text="Delete">
                                            <FontAwesomeIcon
                                                role="button"
                                                icon={faTrash}
                                                size="1x"
                                                className="deleteIcon"
                                                onClick={() => {
                                                    setBlogId(item?.id);
                                                    setVisible(true);
                                                }}
                                            />
                                        </Tooltip>
                                    </>
                                </CTableDataCell> */}
                            </CTableRow>
                        ))}
                    </CTableBody>
                </CTable>
                <div className="pagin-div">
                    <div>
                        Showing {indexOfFirstRow + 1} to{" "}
                        {Math?.min(indexOfLastRow, data?.blogData?.data?.length)} of{" "}
                        {data?.blogData?.data?.length} entries
                    </div>
                    <div className="pagination-container">
                        <ReactPaginate
                            activePage={currentPage}
                            itemsCountPerPage={rowsPerPage}
                            totalItemsCount={data?.blogData?.data?.length}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            prevPageText="Previous"
                            nextPageText="Next"
                            firstPageText="First"
                            lastPageText="Last"
                            innerClass="pagination"
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>
                </div>
            </CCardBody>
            <DeletePopMsg
                visible={visible}
                setVisible={setVisible}
                id={blogId}
                deleteFunction={deleteBlog}
            />
        </CCard>
    );
}
