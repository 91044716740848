import React, { useState, useEffect } from "react";
import {
  CFormInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormLabel,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Tooltip from "src/components/Tooltip";
import DeletePopMsg from "src/components/modal/DeletePopMsg";
import {
  getAllGst,
  getSingleGst,
  deleteGst,
  updateServiceTab,
  addServiceTab,
  uploadTextEditorImage,
} from "src/api/service";
import axios from "axios";

export default function GstPage(GSTDatabyID) {
  const router = useLocation();

  const gstObj = {
    title: "",
    description: "",
    image: "",
    serviceId: router?.state?.id,

  };

  const [gstAction, setGstAction] = useState("create");
  const [gstId, setGstId] = useState("");
  const [gstSection, setGstSection] = useState(gstObj);
  const [gstData, setGstData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [serviceName, setServiceName] = useState(router.state.name);
  const [description, setdescription] = useState("");

  useEffect(() => {
    getGstFunction();
  }, []);

  const handleGst = (e) => {
    const { name, value } = e.target;
    setGstSection({ ...gstSection, [name]: value });
  };

  const handleGstSection = () => {
    const payload = {
      description: description,
      videoUrl: gstSection.image,
      title: gstSection.title,
      serviceId: router?.state?.id,
    };
    if (gstAction === "create") {
      addServiceTab(payload)
        .then((res) => {
          getGstFunction();
          setdescription("");
          setGstSection(gstObj);
          setGstAction("create");
        })
        .catch((err) => alert("err", err));
    } else {
      updateServiceTab(gstId, payload)
        .then((res) => {
          getGstFunction();
          setdescription("");
          setGstSection(gstObj);
          setGstAction("create");
        })
        .catch((err) => alert("err", err));
    }
  };

  const getSingleGstSection = (id) => {
    setGstSection({ ...gstSection, serviceId: id });
    getSingleGst(id)
      .then((res) => {
        setdescription(res.data.description);
        setGstSection(res.data);
      })
      .catch((err) => alert("err", err));
  };

  const getGstFunction = () => {
    getAllGst()
      .then((res) => {
        setGstData(res?.data);
      })
      .catch((err) => alert("err", err));
  };

  const deleteGstSection = (id) => {
    deleteGst(id)
      .then((res) => {
        getGstFunction();
        setVisible(false);
      })
      .catch((err) => alert("err", err));
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setdescription(data);
  };
  const editorConfiguration = {
    extraPlugins: [MyCustomUploadAdapterPlugin] // Register the custom plugin
  };

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            const formData = new FormData();
            formData.append("file", file);
            uploadTextEditorImage(formData)
              .then((response) => {
                resolve({ default: response.data.data.Location });
              })
              .catch((error) => {
                console.error("Error uploading image:", error);
                reject(error);
              });
          });
        });
      },
    };
  };

  return (
    <CCard>
      <CCardHeader>
        <strong>Tab Section</strong>
      </CCardHeader>
      <CCardBody>
        <CTable responsive className="table table-bordered">
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">S.No.</CTableHeaderCell>
              <CTableHeaderCell scope="col">Tab Title</CTableHeaderCell>

              {/* <CTableHeaderCell scope="col">Description</CTableHeaderCell> */}
              <CTableHeaderCell scope="col">Service Name</CTableHeaderCell>

              <CTableHeaderCell scope="col">Action</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {GSTDatabyID?.GSTDatabyID?.length > 0 &&
              GSTDatabyID?.GSTDatabyID?.map((item, index) => {
                return (
                  <CTableRow key={index}>
                    {/* <CTableDataCell>{item.id || "-"}</CTableDataCell> */}
                    <CTableDataCell>{index + 1}</CTableDataCell>
                    <CTableDataCell>{item?.title}</CTableDataCell>

                    <CTableDataCell>
                      {/* {serviceOptions.serviceOptions.length > 0
                        ? serviceOptions.serviceOptions.find(
                          (option) => option.value === item.serviceId
                        )?.label
                        : ""} */}
                      {serviceName}
                    </CTableDataCell>

                    <CTableDataCell>
                      <>
                        <Tooltip text="View">
                          <FontAwesomeIcon
                            role="button"
                            icon={faEye}
                            size="1x"
                            className="viewIcon"
                          />
                        </Tooltip>

                        <Tooltip text="Update">
                          <FontAwesomeIcon
                            role="button"
                            icon={faPenToSquare}
                            size="1x"
                            className="px-3 updateIcon"
                            onClick={() => {
                              setGstAction("edit");
                              setGstId(item?.id);
                              getSingleGstSection(item?.id);
                            }}
                          />
                        </Tooltip>

                        <Tooltip text="Delete">
                          <FontAwesomeIcon
                            onClick={() => {
                              setGstAction("create");
                              setGstId(item?.id);
                              setVisible(true);
                            }}
                            role="button"
                            icon={faTrash}
                            size="1x"
                            className="deleteIcon"
                          />
                        </Tooltip>
                      </>
                    </CTableDataCell>
                  </CTableRow>
                );
              })}

            {/* ))} */}
          </CTableBody>
        </CTable>
      </CCardBody>
      <br />

      <CCardBody>
        {/* <Container> */}
        <CForm>
          <CRow className="align-items-start">
            <CCol>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                label="Title"
                name="title"
                value={gstSection.title}
                onChange={handleGst}
                aria-describedby="exampleFormControlInputHelpInline"
              />
            </CCol>

            <CCol>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                label="YouTube Url"
                name="image"
                value={gstSection.image}
                onChange={handleGst}
                aria-describedby="exampleFormControlInputHelpInline"
              />
            </CCol>
          </CRow>
          <br />
          <CRow className="align-items-start">
            <CFormLabel>Description</CFormLabel>
            <CKEditor
              editor={ClassicEditor}
              data={description}
              onChange={handleEditorChange}
              config={editorConfiguration}
            />
          </CRow>
          <br />

          <br />
          <CButton onClick={handleGstSection}>Submit</CButton>
        </CForm>
        {/* </Container> */}
      </CCardBody>

      <DeletePopMsg
        visible={visible}
        setVisible={setVisible}
        id={gstId}
        deleteFunction={deleteGstSection}
      />
    </CCard>
  );
}
