import request from "src/services/request";
import { API_BASE_URL } from "src/services/const";

export const addHeader = (data) =>
  request(`${API_BASE_URL}/headers`, {
    method: "POST",
    data,
  });

export const getHeaders = () => {
  return request(`${API_BASE_URL}/headers`, {
    method: "GET",
  });
};

export const getHeaderById = (id) =>
  request(`${API_BASE_URL}/headers/${id}`, {
    method: "GET",
  });

export const updateHeader = (id, data) =>
  request(`${API_BASE_URL}/headers/${id}`, {
    method: "PATCH",
    data,
  });
export const deleteHeader = (id) => {
  return request(`${API_BASE_URL}/headers/${id}`, {
    method: "DELETE",
  });
};

//subMenu 
export const addSubMenu = (data) =>
  request(`${API_BASE_URL}/submenu`, {
    method: "POST",
    data,
  });

export const getSubMenu = () => {
  return request(`${API_BASE_URL}/submenu`, {
    method: "GET",
  });
};

export const getSubMenuById = (id) =>
  request(`${API_BASE_URL}/submenu/${id}`, {
    method: "GET",
  });

export const updateSubMenu = (id, data) =>
  request(`${API_BASE_URL}/submenu/${id}`, {
    method: "PATCH",
    data,
  });
export const deleteSubMenu = (id) => {
  return request(`${API_BASE_URL}/submenu/${id}`, {
    method: "DELETE",
  });
};