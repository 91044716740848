import React, { useEffect, useState } from "react";
import {
  CForm,
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CFormLabel,
} from "@coreui/react";
import { useLocation } from "react-router-dom";
import { viewHeroData } from "src/api/heroApi";

const ViewHeroForm = () => {
  const route = useLocation();
  const { heroId, isView } = route?.state;
  const initialState = {
    title: "",
    sub_title: "",
    image: "",
    description: "",
  };

  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    if (isView) {
      getHeroDataByID();
    }
  }, [isView]);


  const getHeroDataByID = async () => {
    try {
      const response = await viewHeroData(heroId);
      setFormData(response.data);
    } catch (err) {
      console.error("The error in viewing hero section data by id: ", err);
    }
  }


  const { title, sub_title, image, description } = formData;

  const renderCategoryForm = () => {
    return (
      <CCard>
        <CCardHeader><strong>Display Hero Section</strong></CCardHeader>
        <CCardBody>
          <CForm>
            <CRow>
              <CCol xs={12} >
                <CFormLabel><strong>Hero Section Title :</strong></CFormLabel> {` ${title}`}
              </CCol>
              <CCol xs={12}>
                <CFormLabel><strong>Sub-Title :</strong></CFormLabel> {` ${sub_title}`}
              </CCol>
              <CCol xs={12} >
                <CFormLabel><strong>Image :</strong></CFormLabel> {` ${image}`}
              </CCol>
              <CCol xs={12} className="marMobile mb-4">
                <CFormLabel><strong>Description :</strong></CFormLabel>  {` ${description}`}
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
    );
  };

  return renderCategoryForm();
};

export default ViewHeroForm;
