import request from "src/services/request";
import { API_BASE_URL } from "src/services/const";

export const addBlogData = (data) => request(`${API_BASE_URL}/blog-page`, {
  method: "POST",
  data,
  // headers: new Headers({
  //   "Content-Type": "multipart/form-data",
  // }),
});

export const getAllBlogs = () => request(`${API_BASE_URL}/blog-page`, { method: "GET" });

export const getBlogById = (id) => request(`${API_BASE_URL}/blog-page/${id}`, { method: "GET" });

export const updateBlogs = (id, data) => request(`${API_BASE_URL}/blog-page/${id}`, { method: "PATCH", data });

export const deleteBlogs = (id, data) => request(`${API_BASE_URL}/blog-page/${id}`, { method: "DELETE", data });

//Hero Section
export const addHeroSection = (data) => request(`${API_BASE_URL}/blog-page-hero-section`, {
  method: "POST",
  data,
  headers: new Headers({
    "Content-Type": "multipart/form-data",
  }),
});

export const getHeroSection = () => request(`${API_BASE_URL}/blog-page-hero-section`, { method: "GET" });

export const getSingleHeroSection = (id) => {
  return request(`${API_BASE_URL}/blog-page-hero-section/${id}`, {
    method: "GET",
  });
};
export const updateHeroSection = (id, data) => {
  return request(`${API_BASE_URL}/blog-page-hero-section/${id}`, {
    method: "PATCH",
    data,
  });
};
export const deleteHeroSection = (id) => {
  return request(`${API_BASE_URL}/blog-page-hero-section/${id}`, {
    method: "DELETE",
  });
};

//Tabs Section
export const addTabs = (data) =>
  request(`${API_BASE_URL}/blog-page-tab-section`, {
    method: "POST",
    data,
    headers: new Headers({
      "Content-Type": "multipart/form-data",
    }),
  });

export const getAllTabs = () => {
  return request(`${API_BASE_URL}/blog-page-tab-section`, {
    method: "GET",
  });
};

export const getSingleTabs = (id) => {
  return request(`${API_BASE_URL}/blog-page-tab-section/${id}`, {
    method: "GET",
  });
};
export const updateTab = (id, data) => {
  return request(`${API_BASE_URL}/blog-page-tab-section/${id}`, {
    method: "PATCH",
    data,
    headers: new Headers({
      "Content-Type": "multipart/form-data",
    }),
  });
};
export const deleteTab = (id) => {
  return request(`${API_BASE_URL}/blog-page-tab-section/${id}`, {
    method: "DELETE",
  });
};
//Blogs Section
export const addBlogs = (data) =>
  request(`${API_BASE_URL}/blog-page-blogs`, {
    method: "POST",
    data,
  });

// export const getAllBlogs = () => {
//   return request(`${API_BASE_URL}/blog-page-blogs`, {
//     method: "GET",
//   });
// };

export const getSingleBlogs = (id) => {
  return request(`${API_BASE_URL}/blog-page-blogs/${id}`, {
    method: "GET",
  });
};
export const updateBlog = (id, data) => {
  return request(`${API_BASE_URL}/blog-page-blogs/${id}`, {
    method: "PATCH",
    data,
  });
};
export const deleteBlog = (id) => {
  return request(`${API_BASE_URL}/blog-page-blogs/${id}`, {
    method: "DELETE",
  });
};

//Category Section
export const addCategories = (data) =>
  request(`${API_BASE_URL}/blog-page-categories`, {
    method: "POST",
    data,
  });

export const getSingleCategories = (id) => {
  return request(`${API_BASE_URL}/blog-page-categories/${id}`, {
    method: "GET",
  });
};

export const updateCategory = (id, data) => {
  return request(`${API_BASE_URL}/blog-page-categories/${id}`, {
    method: "PATCH",
    data,
  });
};
export const deleteCategory = (id) => {
  return request(`${API_BASE_URL}/blog-page-categories/${id}`, {
    method: "DELETE",
  });
};
