import request from "src/services/request";
import { API_BASE_URL } from "src/services/const";

//add-hero
export const addhome_Hero = (data) => request(`${API_BASE_URL}/home-hero-section`, {
  method: "POST",
  data,
  headers: new Headers({
    "Content-Type": "multipart/form-data",
  }),
});

export const gethome_Hero = () => request(`${API_BASE_URL}/home-hero-section`, {
  method: "GET"
});

export const getHeroSection = () => request(`${API_BASE_URL}/hero-section`, { method: "GET" });

export const deleteHeroData = (id) => request(`${API_BASE_URL}/home-hero-section/${id}`, {
  method: "DELETE"
});

//hero-data-by-id
export const viewHeroData = (id) => request(`${API_BASE_URL}/home-hero-section/${id}`, {
  method: "GET"
});