import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CCardHeader,
  CFormInput,
  CFormSelect,
  CButton,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import DeletePopMsg from "../../components/modal/DeletePopMsg";
import Tooltip from "src/components/Tooltip";
import ReactPaginate from "react-js-pagination";
import UserPopForm from "src/components/modal/UserPopForm";
import { deleteUser, getAllUsers } from "src/api/loginApi";

const Users = () => {
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [visible, setVisible] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [userID, setUserID] = useState("");
  const [editUser, setEditUser] = useState("");

  useEffect(() => {
    getUsers();
  }, []);

  const deleteUsers = (id) => {
    deleteUser(id)
      .then((res) => {
        setVisible(false);
      })
      .catch((err) => console.log("err", err));
  };

  const getUsers = async () => {
    try {
      const response = await getAllUsers();
      setAllUsers(response?.user_data);
    } catch (err) {
      console.error("error", err);
    }
  };

  const handleEdit = (data) => {
    setEditUser(data);
    setShowEditForm(!showEditForm);
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = allUsers?.slice(indexOfFirstRow, indexOfLastRow);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <CCard>
      <CCardHeader>
        <strong>Users Page</strong>
      </CCardHeader>
      <div className="search_bar">
        <div className="search-inner">
          <div>
            <CFormSelect
              size="sm"
              className="mb-3"
              style={{ cursor: "pointer" }}
              aria-label="Small select example"
              onChange={(e) => setRowsPerPage(e.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </CFormSelect>
          </div>
          <div style={{ display: "flex" }}>
            <>
              <CFormInput
                type="text"
                size="sm"
                placeholder="Search"
                aria-label="sm input example"
                style={{ margin: "0px 0px 16px 0px" }}
                onChange={handleSearchChange}
                value={searchInput}
              />
              <span
                style={{
                  border: " 1px solid #d1c5c5",
                  borderRadius: "4px",
                  margin: "0px 5px 16px -1px",
                  padding: "1px 8px 0px 8px",
                  background: "#ebedef",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon role="button" icon={faSearch} size="1x" />
              </span>
            </>
          </div>
          <div>
            <CButton onClick={toggleForm} className="float-end btn-addbtn">
              Add
            </CButton>
            {showForm ? <UserPopForm /> : null}
          </div>
        </div>
      </div>
      <CCardBody className="pb-0">
        <CTable
          responsive
          striped
          hover
          className="table table-bordered custom-table-border row-hover"
        >
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col" className="col-md-1 purpleRow">
                S. No.
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="col-md-3 purpleRow ">
                Name
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="col-md-3 purpleRow ">
                Email
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="col-md-3 purpleRow ">
                Phone
              </CTableHeaderCell>
              <CTableHeaderCell scope="col" className="col-md-2 purpleRow ">
                Action
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {currentRows?.length > 0 &&
              currentRows
                ?.filter((item) =>
                  item?.first_name
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())
                )
                ?.map((item, index) => {
                  return (
                    <CTableRow key={index}>
                      <CTableDataCell>{index + 1}</CTableDataCell>
                      <CTableDataCell>
                        {item?.first_name} {item?.last_name}
                      </CTableDataCell>
                      <CTableDataCell>{item?.email}</CTableDataCell>
                      <CTableDataCell>{item?.mobile}</CTableDataCell>
                      <CTableDataCell className="col-md-3">
                        <>
                          <Tooltip text="Update">
                            <FontAwesomeIcon
                              role="button"
                              icon={faPenToSquare}
                              size="1x"
                              onClick={() => handleEdit(item)}
                              className="px-3 updateIcon"
                            />
                          </Tooltip>
                          <Tooltip text="Delete">
                            <FontAwesomeIcon
                              onClick={() => {
                                setUserID(item.id);
                                setVisible(true);
                              }}
                              role="button"
                              icon={faTrash}
                              size="1x"
                              className="deleteIcon"
                            />
                          </Tooltip>
                        </>
                      </CTableDataCell>
                    </CTableRow>
                  );
                })}
            {showEditForm ? (
              <UserPopForm
                userData={editUser}
                showPassword={true}
                editTitle={"Edit User"}
              />
            ) : null}
          </CTableBody>
        </CTable>
        <div className="pagin-div">
          <div>
            Showing {indexOfFirstRow + 1} to{" "}
            {Math?.min(indexOfLastRow, allUsers?.length)} of {allUsers?.length}{" "}
            entries
          </div>
          <div className="pagination-container">
            <ReactPaginate
              activePage={currentPage}
              itemsCountPerPage={rowsPerPage}
              totalItemsCount={1}
              // {Directors?.data?.length}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              prevPageText="Previous"
              nextPageText="Next"
              firstPageText="First"
              lastPageText="Last"
              innerClass="pagination"
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        </div>
      </CCardBody>
      <DeletePopMsg
        visible={visible}
        setVisible={setVisible}
        id={userID}
        deleteFunction={deleteUsers}
      />
    </CCard>
  );
};

export default Users;
