import React, { useEffect, useState } from "react";
import {
  CFormInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CFormSelect,
  CFormLabel,
  CFormTextarea,
  CFormCheck,
} from "@coreui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addServices,
  getServicesById,
  updateService,
  getSubMenu,
  getAllServices,
  addServicesSlug,
  updateServicesSlug,
  getServicesSlug,
  uploadCSVFile,
} from "src/api/service";
import * as XLSX from "xlsx";
import axios from "axios";

const ManageService = () => {
  let { id } = useParams();
  const route = useLocation();

  const servicePageId = id;
  const isUpdate = true;

  const initialState = {
    serviceName: "",
    submenuId: "",
    serviceMetaDescription: "",
    serviceMetaTitle: "",
    serviceSlugUrl: "",
    serviceMetaKeyword: "",
    isVisibleSubMenu: "",
    // isStateCityVisible:"",
  };

  const [clicked, setClicked] = useState({
    serviceName: false,
    serviceSlugUrl: false,
    serviceMetaDescription: false,
    serviceMetaTitle: false,
    serviceMetaKeyword: false,
    isVisibleSubMenu: false,
    // isStateCityVisible:false,
    submenuId: false,
  });

  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialState);
  const [options, setOption] = useState(null);
  const [file, setFile] = useState(null);
  const [popularSearch, setPopularSearch] = useState(true);
  const [relatedSearch, setRelatedSearch] = useState(false);

  // search state/city
  const slugObj = {
    service_name: "",
    slugUrl: "",
    serviceId: "",
    isState: "",
  };

  // search state/citys
  const [slugAction, setSlugAction] = useState("create");
  const [slugID, setSlugID] = useState("");
  const [slugSection, setSlugSection] = useState(slugObj);
  const [visible, setVisible] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const [service_slugUrl, setService_slugUrl] = useState();
  const [serviceStateData, setServiceStateData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isStateCityVisible, setIsStateCityVisible] = useState(false);

  useEffect(() => {
    if (isUpdate) {
      getServicesData();
    }
  }, [isUpdate]);

  useEffect(() => {
    getSubMenus();
    getAllServiceData();
  }, []);

  useEffect(() => {
    getServicesStateCity();
  }, [serviceData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const getServicesData = () => {
    getServicesById(servicePageId)
      .then((res) => {
        setFormData(res?.data[0]);
      })
      .catch((err) => console.log("Create Service Page From Get", err));
  };
  // state city all data
  const getAllServiceData = () => {
    getAllServices()
      .then((res) => setServiceData(res?.data))
      .catch((e) => console.log("error get all services", e));
  };

  const getServicesStateCity = async () => {
    try {
      const res = await getServicesSlug();
      setServiceStateData(res?.data);
      const filteredData = res?.data?.filter(
        (item) => item?.slugUrl === serviceSlugUrl
      );
      setSlugSection(filteredData[0]);
      setSearchInput(filteredData[0]?.service_name);
    } catch (err) {
      alert("Server Error", err);
    }
  };

  const getSubMenus = () => {
    getSubMenu()
      .then((res) => {
        setOption(
          res.data.map((menu) => {
            return {
              label: menu.submenuName,
              value: menu.id,
            };
          })
        );
      })
      .catch((err) => { });
  };

  const handleSubmit = () => {
    let formIsValid = true;
    const errorsCopy = { ...errors };

    if (!submenuId) {
      errorsCopy.submenuId = "Sub-Menu Id is required";
      formIsValid = false;
    } else {
      errorsCopy.submenuId = "";
    }

    if (!serviceName) {
      errorsCopy.serviceName = "Service Name is required";
      formIsValid = false;
    } else {
      errorsCopy.serviceName = "";
    }

    if (!serviceSlugUrl) {
      errorsCopy.serviceSlugUrl = "Service Slug-Url is required";
      formIsValid = false;
    } else {
      errorsCopy.serviceSlugUrl = "";
    }

    if (!serviceMetaDescription) {
      errorsCopy.serviceMetaDescription =
        "Service Meta-Description is required";
      formIsValid = false;
    } else {
      errorsCopy.serviceMetaDescription = "";
    }

    if (!serviceMetaTitle) {
      errorsCopy.serviceMetaTitle = "service Meta-Title is required";
      formIsValid = false;
    } else {
      errorsCopy.serviceMetaTitle = "";
    }

    if (!serviceMetaKeyword) {
      errorsCopy.serviceMetaKeyword = "service Meta-Keyword is required";
      formIsValid = false;
    } else {
      errorsCopy.serviceMetaKeyword = "";
    }

    if (isVisibleSubMenu === "") {
      errorsCopy.isVisibleSubMenu = "Sub Menu is required";
      formIsValid = false;
    } else {
      errorsCopy.isVisibleSubMenu = "";
    }

    if (isStateCityVisible === "") {
      errorsCopy.isStateCityVisible =
        "State/City visibility selection required";
      formIsValid = false;
    } else {
      errorsCopy.isStateCityVisible = "";
    }

    setErrors(errorsCopy);

    if (formIsValid) {
      const payload = {
        serviceName,
        submenuId,
        serviceMetaDescription,
        serviceMetaTitle,
        serviceSlugUrl,
        serviceMetaKeyword,
        isVisibleSubMenu,
      };

      if (servicePageId == undefined) {
        addServices(payload)
          .then((res) => {
            const payloadB = {
              service_name: res?.data?.serviceName,
              slugUrl: res?.data?.serviceSlugUrl,
              serviceId: res?.data?.id,
              isState: isStateCityVisible,
            };
            addServicesSlug(payloadB)
              .then((res) => { })
              .catch((err) => alert("error in add services slug", err));

            navigate("/services/service-form", {
              state: {
                id: res?.data?.id,
                name: res?.data?.serviceName,
              },
            });
            setFormData(initialState);
          })
          .catch((err) => console.log("Create Service Page From Add", err));
      } else {
        updateService(servicePageId, payload)
          .then((res) => {
            const payloadB = {
              service_name: slugSection?.service_name,
              slugUrl: service_slugUrl,
              serviceId: servicePageId,
              isState: slugSection?.isState,
            };
            updateServicesSlug(payloadB)
              .then((res) => { })
              .catch((err) => alert("err update", err));
            navigate("/services/service-form", {
              state: {
                id: servicePageId,
                name: formData?.serviceName,
              },
            });
          })
          .catch((err) => console.log("Create Service Page From Update", err));
      }
    }
  };

  const handleInputFocus = (fieldName) => {
    setClicked({ ...clicked, [fieldName]: true });
    setErrors({ ...errors, [fieldName]: "" });
  };

  const {
    serviceName,
    submenuId,
    serviceMetaDescription,
    serviceMetaTitle,
    serviceSlugUrl,
    serviceMetaKeyword,
    isVisibleSubMenu,
  } = formData;

  const handleSelectedService = (selectedService) => {
    setSearchInput(selectedService);
    const selectedServiceData = serviceData.find(
      (service) => service.serviceName === selectedService
    );
    if (selectedServiceData) {
      setSlugSection({
        ...slugSection,
        service_name: selectedServiceData.serviceName,
        slugUrl: selectedServiceData.serviceSlugUrl,
      });
      setService_slugUrl(selectedServiceData.serviceSlugUrl);
    } else {
      setSlugSection({ ...slugSection, service_name: "", slugUrl: "" });
      setService_slugUrl("");
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };
  const handleFileUpload = (event) => {
    event.preventDefault();
    if (!file) {
      alert("Please select a file to upload");
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    uploadCSVFile(formData)
      .then((response) => {
        alert("File uploaded successfully!");
      })
      .catch((error) => {
        alert("Failed to upload the file.");
      });
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (slugAction === "edit" && name === "SlugUrl" && !value) {
      setSlugSection({ ...slugSection, [name]: service_slugUrl });
    } else {
      setSlugSection({ ...slugSection, [name]: value });
    }
  };

  const renderCategoryForm = () => {
    return (
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Create Service</strong>
        </CCardHeader>
        <CCardBody>
          <CForm>
            <CRow className="align-items-start">
              <CCol>
                <CFormLabel>Select Menu Id</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormSelect
                  aria-label="Parent"
                  name="submenuId"
                  value={submenuId}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Open this select menu
                  </option>
                  {options?.map((optionData, index) => {
                    return (
                      <option key={index} value={optionData.value}>
                        {optionData.label}
                      </option>
                    );
                  })}
                </CFormSelect>
                {errors.submenuId && (
                  <div className="text-danger">{errors.submenuId}</div>
                )}
              </CCol>
              <CCol>
                <CFormLabel>Service Name</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  placeholder="Enter Service Name"
                  name="serviceName"
                  value={serviceName}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("serviceName")}
                  aria-describedby="exampleFormControlInputHelpInline"
                />
                {errors.serviceName && (
                  <div className="text-danger">{errors.serviceName}</div>
                )}
              </CCol>
            </CRow>
            <br />
            <CRow className="align-items-start">
              <CCol>
                <CFormLabel>Slug URL</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  placeholder="Enter Slug URL"
                  name="serviceSlugUrl"
                  value={serviceSlugUrl}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("serviceSlugUrl")}
                  aria-describedby="exampleFormControlInputHelpInline"
                />
                {errors.serviceSlugUrl && (
                  <div className="text-danger">{errors.serviceSlugUrl}</div>
                )}
              </CCol>
              <CCol>
                <CFormLabel>Meta Title</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  placeholder="Enter Meta Title"
                  name="serviceMetaTitle"
                  value={serviceMetaTitle}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("serviceMetaTitle")}
                  aria-describedby="exampleFormControlInputHelpInline"
                />
                {errors.serviceMetaTitle && (
                  <div className="text-danger">{errors.serviceMetaTitle}</div>
                )}
              </CCol>
            </CRow>
            <br />
            <CRow className="align-items-start">
              <CCol>
                <CFormLabel>Meta Description </CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormTextarea
                  type="textarea"
                  id="exampleFormControlInput1"
                  placeholder="Enter Meta Description"
                  name="serviceMetaDescription"
                  value={serviceMetaDescription}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("serviceMetaDescription")}
                  aria-describedby="exampleFormControlInputHelpInline"
                  rows="2"
                />
                {errors.serviceMetaDescription && (
                  <div className="text-danger">
                    {errors.serviceMetaDescription}
                  </div>
                )}
              </CCol>
            </CRow>
            <br />
            <CRow className="align-items-start">
              <CCol>
                <CFormLabel>Meta Keywords</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormTextarea
                  type="textarea"
                  id="exampleFormControlInput1"
                  placeholder="Enter Meta Keywords"
                  name="serviceMetaKeyword"
                  value={serviceMetaKeyword}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("serviceMetaKeyword")}
                  aria-describedby="exampleFormControlInputHelpInline"
                  rows="2"
                />
                {errors.serviceMetaKeyword && (
                  <div className="text-danger">{errors.serviceMetaKeyword}</div>
                )}
              </CCol>
            </CRow>
            <br />
            <CRow className="align-items-start">
              <CCol xs={6}>
                <CFormLabel>Upload State City Excel File</CFormLabel>
                <CFormInput
                  type="file"
                  onChange={handleFileChange}
                />
                <br />

                <CButton onClick={handleFileUpload}>Upload  File</CButton>
              </CCol>

              <CCol className="mt-4">
                <CFormCheck
                  type="checkbox"
                  label="Popular Search"
                  checked={popularSearch}
                  onChange={(e) => setPopularSearch(e.target.checked)}
                />
              </CCol>

              <CCol className="mt-4">
                <CFormCheck
                  type="checkbox"
                  label="Related Search"
                  checked={relatedSearch}
                  onChange={(e) => setRelatedSearch(e.target.checked)}
                />
              </CCol>
            </CRow>
            <CRow className="align-items-start">
              <CCol>
                <CFormInput
                  type="text"
                  id="searchInput"
                  label="State/City Service Name"
                  placeholder="Search or Type Service Name"
                  value={searchInput}
                  onChange={(e) => handleSelectedService(e.target.value)}
                  list="serviceList"
                />
                <datalist id="serviceList">
                  {serviceData.map((item, index) => (
                    <option key={index} value={item.serviceName} />
                  ))}
                </datalist>
              </CCol>
              <CCol>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  label="State/City Slug URL"
                  name="slugUrl"
                  value={slugSection?.slugUrl || service_slugUrl || ""}
                  onChange={handleChange}
                  disabled
                  aria-describedby="exampleFormControlInputHelpInline"
                />
              </CCol>
            </CRow>
            <br />
            <CRow className="align-items-start">
              <CCol xs={6}>
                <CFormLabel>State/City Services Menu Visible</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormSelect
                  aria-label="Parent"
                  name="isStateCityVisible"
                  value={isStateCityVisible}
                  onFocus={() => handleInputFocus("isStateCityVisible")}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Open this Select Menu
                  </option>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </CFormSelect>
                {errors.isStateCityVisible && (
                  <div className="text-danger">{errors.isStateCityVisible}</div>
                )}
              </CCol>

              <CCol xs={6}>
                <CFormLabel>Sub Menu Visible</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormSelect
                  aria-label="Parent"
                  name="isVisibleSubMenu"
                  value={isVisibleSubMenu}
                  onFocus={() => handleInputFocus("isVisibleSubMenu")}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Open this Select Menu
                  </option>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </CFormSelect>
                {errors.isVisibleSubMenu && (
                  <div className="text-danger">{errors.isVisibleSubMenu}</div>
                )}
              </CCol>
            </CRow>
            <br />
            <CButton onClick={handleSubmit}>Submit</CButton>
          </CForm>
        </CCardBody>
      </CCard>
    );
  };

  return renderCategoryForm();
};

export default ManageService;
