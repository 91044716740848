import React, { useEffect, useState } from "react";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
  CCard,
  CCardHeader,
  CCardBody,
  // Button,
  CFormInput,
  CFormSelect
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ReactPaginate from "react-js-pagination";
// import ServicePage from "src/components/Services/ServicePage";
import Tooltip from "src/components/Tooltip";
import DeletePopMsg from "src/components/modal/DeletePopMsg";
import { getAllServices, deleteService } from "src/api/service";

const Service = () => {

  const [serviceData, setServiceData] = useState([]);
  // const [stateChange, setStateChange] = useState(false);
  const [visible, setVisible] = useState(false);
  const [serviceId, setServiceId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    getAllServiceData();
  }, []);

  const getAllServiceData = () => {
    getAllServices().then(res => {
      setServiceData(res?.data)
    });
  };

  const deleteServices = (id) => {
    deleteService(id)
      .then((res) => {
        setVisible(false);
      })
      .catch((err) => console.log("err", err));
  };

  const filteredRows = serviceData?.filter((item) =>
    item?.serviceName?.toLowerCase().includes(searchInput.toLowerCase())
  );



  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredRows?.slice(
    indexOfFirstRow,
    indexOfLastRow
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };


  return (
    <>
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Service Page</strong>
        </CCardHeader>
        <div className="search_bar">
          <div className="search-inner">
            <div>
              <CFormSelect
                size="sm"
                className="mb-3"
                style={{ cursor: "pointer" }}
                aria-label="Small select example"
                onChange={(e) => setRowsPerPage(e.target.value)}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </CFormSelect>
            </div>
            <div style={{ display: "flex" }}>
              <>
                <CFormInput
                  type="text"
                  size="sm"
                  placeholder="Search"
                  aria-label="sm input example"
                  style={{ margin: "0px 0px 16px 0px" }}
                  onChange={handleSearchChange}
                  value={searchInput}
                />
                <span
                  style={{
                    border: " 1px solid #d1c5c5",
                    borderRadius: "4px",
                    margin: "0px 5px 16px -1px",
                    padding: "1px 8px 0px 8px",
                    background: "#ebedef",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    role="button"
                    icon={faSearch}
                    size="1x"
                  />
                </span>
              </>
              {/* )} */}
            </div>
            <div>
              <Link to="/services/service/add" state={{ isUpdate: false }}>
                <CButton className="float-end btn-addbtn">Add</CButton>
              </Link>
            </div>
          </div>
        </div>
        {/* top end */}
        <CCardBody className="pb-0">
          {/* <Container isLoading={isLoading}> */}
          <CTable responsive striped hover className="table table-bordered custom-table-border row-hover">
            <CTableHead>
              <CTableRow className="purpleRow">
                <CTableHeaderCell className="col-1 purpleRow" scope="col">S.No.</CTableHeaderCell>
                <CTableHeaderCell className="col-2 purpleRow" scope="col">Title</CTableHeaderCell>
                <CTableHeaderCell className="col-3 purpleRow" scope="col">Meta Description</CTableHeaderCell>
                <CTableHeaderCell className="col-3 purpleRow" scope="col">Meta Title</CTableHeaderCell>
                <CTableHeaderCell className="col-2 purpleRow" scope="col">Slug URL</CTableHeaderCell>
                <CTableHeaderCell className="col-1 purpleRow" scope="col">Action</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>

              {currentRows?.length > 0 && (
                currentRows?.filter((item) =>
                  item?.serviceName
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())
                ))?.map((item, index) => {
                  return (
                    <CTableRow key={index}>
                      <CTableDataCell>{indexOfFirstRow + index + 1}</CTableDataCell>
                      <CTableDataCell>{item?.serviceName || "-"}</CTableDataCell>
                      <CTableDataCell>
                        {item?.serviceMetaDescription.length > 65 ? item.serviceMetaDescription.substring(0, 65) + "..." : item.serviceMetaDescription || "-"}
                      </CTableDataCell>
                      <CTableDataCell>
                        {item?.serviceMetaTitle.length > 65 ? item.serviceMetaTitle.substring(0, 65) + "..." : item.serviceMetaTitle || "-"}
                      </CTableDataCell>
                      <CTableDataCell>{item?.serviceSlugUrl || "-"}</CTableDataCell>
                      <CTableDataCell>
                        <>
                          <Tooltip text="View">
                            <Link
                              to="/services/service/view"
                              state={{
                                servicePageId: item.id,
                                isUpdate: true,
                                serviceDataName: item.serviceName,
                              }}
                            >
                              <FontAwesomeIcon
                                role="button"
                                icon={faEye}
                                size="1x"
                                className="viewIcon"
                              />
                            </Link>
                          </Tooltip>
                          <Tooltip text="Update">
                            <Link
                              to={`/services/service/add/${item.id}`}
                              state={{
                                servicePageId: item.id,
                                isUpdate: true,
                                serviceDataName: item.serviceName,
                              }}
                            // onClick={(event) => handleLinkClick(event, "/services/service/add", {
                            //   servicePageId: item.id,
                            //   isUpdate: true,
                            //   serviceDataName: item.serviceName,
                            // })}
                            >
                              <FontAwesomeIcon
                                role="button"
                                icon={faPenToSquare}
                                size="1x"
                                className="px-3 updateIcon"
                              />
                            </Link>
                            {/* {updateForm ? <p>Update</p> : ''} */}
                          </Tooltip>
                          <Tooltip text="Delete">
                            <FontAwesomeIcon
                              onClick={() => {
                                setServiceId(item.id);
                                setVisible(true);
                              }}
                              role="button"
                              icon={faTrash}
                              size="1x"
                              className="deleteIcon"
                            />
                            {/* {showForm ? <p>Update</p> : ''} */}
                          </Tooltip>
                        </>
                      </CTableDataCell>
                    </CTableRow>
                  );
                })}
            </CTableBody>
          </CTable>
          <div className="pagin-div">
            <div>
              Showing {indexOfFirstRow + 1} to{" "}
              {Math?.min(indexOfLastRow, serviceData.length)} of{" "}
              {serviceData.length} entries
            </div>
            <div className="pagination-container">
              <ReactPaginate
                activePage={currentPage}
                itemsCountPerPage={rowsPerPage}
                totalItemsCount={serviceData?.length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                prevPageText="Previous"
                nextPageText="Next"
                firstPageText="First"
                lastPageText="Last"
                innerClass="pagination"
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </div>
        </CCardBody>
        <DeletePopMsg
          visible={visible}
          setVisible={setVisible}
          id={serviceId}
          deleteFunction={deleteServices}
        />
      </CCard>
      {/* <ServicePage servicesData={serviceData} stateChange={stateChange} setStateChange={setStateChange} /> */}
    </>
  );
};

export default Service;
