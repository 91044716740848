import HeroSection from "../pages/Home/HeroSection";
import Users from "../pages/Users";
import Dashboard from "../pages/Dashboard";
import Services from "../pages//Services";
import ManageService from "src/pages/Services/CreateServicePageForm";
import ServicePage from "src/pages/Services/ServicePage";
import Blogs from "../pages/Blogs";
import ManageBlogs from "src/pages/Blogs/CreateBlogPageForm";
import BlogPage from "src/pages/Blogs/BlogPage";
import AddWorkForm from "src/pages/OurWorks/AddWorkForm";
import SubMenu from "src/pages/Submenu";
import Headers from "src/pages/Headers";
import BlogList from "../pages/BlogList/index";
import ServiceList from "src/pages/ServiceList";
import Testimonials from "src/pages/Testimonials";
import OurWorks from "src/pages/OurWorks";
import Pricing from "src/pages/Pricing";
import TestimonialsForm from "src/pages/Testimonials/TestimonialsForm";
import PricingForm from "src/pages/Pricing/PricingForm";
import HeroForm from "src/pages/Home/HeroSection/HeroForm";
import ViewBlogDetails from "src/pages/Blogs/BlogPage/ViewBlogDetails";
import ViewServices from "src/pages/Services/viewServices";
import ViewHeroForm from "src/pages/Home/HeroSection/ViewHeroForm";
import ViewTestimonials from "src/pages/Testimonials/ViewTestimonials";
import BlogCategory from "src/pages/BlogCategory";
import BlogSubCategory from "src/pages/BlogSubCategory";

const AppRoutes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/users", name: "Users", element: Users },
  { path: "/headers", name: "Headers", element: Headers },
  { path: "/submenu", name: "Sub Menu", element: SubMenu },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  { path: "/home/hero-section", name: "Hero Section", element: HeroSection },
  { path: "/heroform", name: "Hero Form", element: HeroForm },
  { path: "/heroform/:id", name: "Hero Form", element: HeroForm },
  { path: "/viewheroform", name: "View Hero Form", element: ViewHeroForm },
  { path: "/services", name: "Services", element: Services },
  { path: "/services/service/add", name: "AddServices", element: ManageService, },
  { path: "/services/service/add/:id", name: "AddServices", element: ManageService, },
  { path: "/services/service/view", name: "ViewServices", element: ViewServices, },
  { path: "/services/service-form", name: "ServicePage", element: ServicePage, },
  { path: "/blogcategory", name: "BlogCategory", element: BlogCategory },
  { path: "/blogsubcategory", name: "BlogSubCategory", element: BlogSubCategory },

  { path: "/blogs", name: "Blogs", element: Blogs },
  { path: "/blogs/add", name: "AddBlogs", element: ManageBlogs },
  { path: "/blogs/add/:id", name: "AddBlogs", element: ManageBlogs },
  { path: "/blogs/view", name: "ViewBlogs", element: ViewBlogDetails },
  { path: "/blogs/blog-form", name: "BlogPage", element: BlogPage, },
  { path: "/blog-list", name: "BlogList", element: BlogList, },
  { path: "/service-list", name: "ServiceList", element: ServiceList, },
  { path: "/testimonials", name: "Testimonials", element: Testimonials, },
  { path: "/testimonialsform", name: "testimonialsform", element: TestimonialsForm, },
  { path: "/testimonialsform/:id", name: "testimonialsform", element: TestimonialsForm, },
  { path: "/testimonials/viewsingle", name: "ViewTestimonials", element: ViewTestimonials, },
  { path: "/ourworks", name: "OurWorks", element: OurWorks, },
  { path: "/addworkform", name: "OurWorksForm", element: AddWorkForm, },
  { path: "/pricing", name: "Pricing", element: Pricing, },
  { path: "/pricingform", name: "PricingForm", element: PricingForm, }
];

export default AppRoutes;
