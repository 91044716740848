// import { provider } from "../provider";
import React, { useEffect, useState } from "react";
import {
  CFormInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CFormLabel,
} from "@coreui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { getServices, getSingleTestinomial } from "src/api/service";
import { addBlogData, getBlogById, updateBlogs } from "src/api/blog";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const ViewTestimonials = () => {

  const route = useLocation();
  const { testimonialID, isUpdate } = route?.state;

  const initialState = {
    personName: "",
    description: "",
    subTitle: "",
    image: "",
    title: "",
  };

  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialState);
  const [options, setOptions] = useState();
  // const [description, setdescription] = useState("");
  const [singleTestimonial, setSingleTestimonial] = useState("");


  useEffect(() => {
    if (isUpdate) {
      getSingleTestimonialData();
    }
  }, [isUpdate]);

  useEffect(() => {
    getAllServices();
    getSingleTestimonialData();
  }, []);

  const [errors, setErrors] = useState({});

  const [clicked, setClicked] = useState({
    title: false,
    image: false,
    subTitle: false,
    personName: false,
    description: false,
  });

  //getSingleTestonomial
  const getSingleTestimonialData = () => {
    getSingleTestinomial(testimonialID)
      .then((res) => {
        setSingleTestimonial(res?.data);
        setFormData(res?.data);
      })
      .catch((err) => console.log("error in getSingleTestimonialData", err));
  };

  const getAllServices = () => {
    getServices().then((res) => {
      setOptions(
        res?.data?.map((services) => {
          return {
            label: services?.serviceName,
            value: services?.id,
          };
        })
      );
    });
  };

  const { name, designation, description, createdAt, facebookLink, instagramLink, linkedinLink, twitterLink, image, updatedAt } = formData;

  const renderCategoryForm = () => {
    return (
      <CCard>
        <CCardHeader> <strong>View Testimonial Details</strong></CCardHeader>
        <CCardBody>
          <CForm>
            <CRow>
              <CCol xs={12} >
                <CFormLabel><strong>Person name :</strong></CFormLabel> {` ${name}`}
              </CCol>
              <CCol xs={12} >
                <CFormLabel><strong>Designation :</strong></CFormLabel> {` ${designation}`}
              </CCol>
              <CCol xs={12} >
                <CFormLabel><strong>Facebook Link name :</strong></CFormLabel> {` ${facebookLink}`}
              </CCol>
              <CCol xs={12} >
                <CFormLabel><strong>Instagram Link :</strong></CFormLabel> {` ${instagramLink}`}
              </CCol>
              <CCol xs={12} >
                <CFormLabel><strong>Linkedin Link :</strong></CFormLabel> {` ${linkedinLink}`}
              </CCol>
              <CCol xs={12} >
                <CFormLabel><strong>Twitter Link :</strong></CFormLabel> {` ${twitterLink}`}
              </CCol>
              <CCol xs={12} >
                <CFormLabel><strong>Image Uploaded :</strong></CFormLabel> {` ${image}`}
              </CCol>
              <CCol xs={12} className="marMobile mb-4">
                <CFormLabel><strong>Description :</strong></CFormLabel>  {` ${description}`}
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
    );
  };

  return renderCategoryForm();
};

export default ViewTestimonials;
