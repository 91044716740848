import React, { useState, useEffect, useRef } from "react";
import {
  CFormInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormLabel,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import {
  getHeroSection,
  getSingleHeroSection,
  addHeroSection,
  editHeroSection,
  deleteHeroSection,
  uploadTextEditorImage,
} from "src/api/service";
import DeletePopMsg from "src/components/modal/DeletePopMsg";
import { useLocation } from "react-router-dom";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import { API_BASE_URL } from "src/services/const";

export default function HeroPage(heroDatabyID) {
  const router = useLocation();

  const hero = {
    title: "",
    description: "",
    serviceId: router?.state?.id,
    subtitle: ''
  };

  const [heroAction, setHeroAction] = useState("create");
  const [heroId, setId] = useState("");
  const [heroSection, setHeroSection] = useState(hero);
  const [heroData, setheroData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [description, setdescription] = useState("");
  const imageRef = useRef();

  const [serviceName, setServiceName] = useState(router.state.name);
  useEffect(() => {
    getHerosSection();
  }, []);

  const getHerosSection = () => {
    getHeroSection()
      .then((res) => {
        setheroData(res?.data);
      })
      .catch((err) => alert("err", err));
  };


  const handlehero = (e) => {
    const { name, value } = e.target;
    setHeroSection({ ...heroSection, [name]: value });
  };

  const handleHeroSection = () => {
    const formData = new FormData();
    formData.append("title", heroSection.title);
    formData.append("description", description);
    formData.append("image", imageRef?.current?.files[0]);
    formData.append("subtitle", heroSection.subtitle);
    formData.append("serviceId", router?.state?.id);

    if (heroAction === "create") {
      addHeroSection(formData)
        .then((res) => {
          getHerosSection();
          setdescription("");
          setHeroSection(hero);
          setHeroAction("create");
        })
        .catch((err) => alert("err", err));
    } else {
      editHeroSection(heroId, formData)
        .then((res) => {
          getHerosSection();
          setdescription("");
          setHeroSection(hero);
          setHeroAction("create");
        })
        .catch((err) => alert("err", err));
    }
  };

  const getSingleSection = (id) => {
    setHeroSection({ ...heroSection, serviceId: id });
    getSingleHeroSection(id)
      .then((res) => {
        setdescription(res.data.description);
        setHeroSection(res.data);
      })
      .catch((err) => alert("err", err));
  };

  const deleteHeroData = (id) => {
    deleteHeroSection(id)
      .then((res) => {
        getHerosSection();

        setVisible(false);
      })
      .catch((err) => alert("err", err));
  };
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setdescription(data);
    // setTabSection({...tabSection,description:data})
  };
  const editorConfiguration = {
    extraPlugins: [MyCustomUploadAdapterPlugin] // Register the custom plugin
  };

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            const formData = new FormData();
            formData.append("file", file);
            uploadTextEditorImage(formData)
              .then((response) => {
                resolve({ default: response.data.data.Location });
              })
              .catch((error) => {
                console.error("Error uploading image:", error);
                reject(error);
              });
          });
        });
      },
    };
  };

  return (
    <CCard>
      <CCardHeader>
        <strong>Hero Section</strong>
      </CCardHeader>
      <CCardBody>
        <CTable responsive className="table table-bordered">
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">S.No.</CTableHeaderCell>
              <CTableHeaderCell scope="col">Title</CTableHeaderCell>
              {/* <CTableHeaderCell scope="col">Description</CTableHeaderCell> */}
              <CTableHeaderCell scope="col">Service Name</CTableHeaderCell>

              <CTableHeaderCell scope="col">Action</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {/* {heroData?.length > 0 &&
              heroData?.map((item, index) => { */}

            {(Array.isArray(heroDatabyID?.heroDatabyID) ? heroDatabyID?.heroDatabyID : [heroDatabyID?.heroDatabyID])?.map((item, index) => {
              return (
                <CTableRow key={index}>
                  {/* <CTableDataCell>{item.id || "-"}</CTableDataCell> */}
                  <CTableDataCell>{index + 1}</CTableDataCell>
                  <CTableDataCell>{item?.title}</CTableDataCell>

                  <CTableDataCell>
                    {serviceName}
                    {/* {serviceOptions.serviceOptions.length > 0
                        ? serviceOptions.serviceOptions.find(
                          (option) => option.value === item.serviceId
                        )?.label
                        : ""} */}
                  </CTableDataCell>

                  <CTableDataCell>
                    <>
                      <Tooltip text="View">
                        <FontAwesomeIcon
                          role="button"
                          icon={faEye}
                          size="1x"
                          className="viewIcon"
                        />
                      </Tooltip>

                      <Tooltip text="Update">
                        <FontAwesomeIcon
                          role="button"
                          icon={faPenToSquare}
                          size="1x"
                          className="px-3 updateIcon"
                          onClick={() => {
                            setHeroAction("edit");
                            setId(item?.id);
                            getSingleSection(item?.id);
                          }}
                        />
                      </Tooltip>

                      <Tooltip text="Delete">
                        <FontAwesomeIcon
                          onClick={() => {
                            setHeroAction("create");
                            setId(item?.id);
                            setVisible(true);
                          }}
                          role="button"
                          icon={faTrash}
                          size="1x"
                          className="deleteIcon"
                        />
                      </Tooltip>
                    </>
                  </CTableDataCell>
                </CTableRow>
              );
            })}

            {/* ))} */}
          </CTableBody>
        </CTable>
      </CCardBody>
      <br />
      <CCardBody>
        {/* <Container> */}
        <CForm>
          <CRow className="align-items-start">

            <CCol xs={6}>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                label="Service Name"
                value={serviceName ? serviceName : router?.state?.name}
                aria-describedby="exampleFormControlInputHelpInline"
                disabled
              />
            </CCol>
            <CCol xs={6}>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                label="Title"
                name="title"
                value={heroSection.title}
                onChange={handlehero}
                aria-describedby="exampleFormControlInputHelpInline"
              />
            </CCol>

          </CRow>
          <br />
          <CRow className="align-items-start">
            <CCol xs={6}>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                label="Sub Title"
                name="subtitle"
                value={heroSection.subtitle}
                onChange={handlehero}
                aria-describedby="exampleFormControlInputHelpInline"
              />
            </CCol>
            <CCol xs={6}>
              <CFormInput
                type="file"
                id="exampleFormControlInput1"
                label="Image"
                name="image"
                ref={imageRef}
                onChange={handlehero}
                aria-describedby="exampleFormControlInputHelpInline"
              />

              {heroSection.image ? (
                <p>
                  {heroSection.image.name
                    ? heroSection?.image?.name
                    : heroSection.image}
                </p>
              ) : (
                ""
              )}
            </CCol>
          </CRow>
          <br />
          <CRow className="align-items-start">
            <CFormLabel>Description</CFormLabel>
            <CKEditor
              editor={ClassicEditor}
              data={description}
              onChange={handleEditorChange}
              config={editorConfiguration}
            />
          </CRow>
          <br />

          <br />
          <CButton onClick={handleHeroSection}>Submit</CButton>
        </CForm>
        {/* </Container> */}
      </CCardBody>

      <DeletePopMsg
        visible={visible}
        setVisible={setVisible}
        id={heroId}
        deleteFunction={deleteHeroData}
      />
    </CCard>
  );
}
