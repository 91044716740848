import request from "src/services/request";
import { API_BASE_URL } from "src/services/const";

//Login
export const userLogin = (data) => request(`${API_BASE_URL}/login`, {
  method: "POST",
  data,
});

//user apis
export const getAllUsers = () => request(`${API_BASE_URL}/getAllUser`, {
  method: "GET"
});

export const deleteUser = (id) => request(`${API_BASE_URL}/deleteUser/${id}`, {
  method: "DELETE",
  // data,
});


export const addUser = (data) => request(`${API_BASE_URL}/createUser`, {
  method: "POST",
  data,
});
