import React, { useEffect, useState } from "react";
import TabsPage from "src/components/Blogs/Tab";
import HeroSection from "src/components/Blogs/HeroSection";
import { useLocation } from "react-router-dom";
import { getBlogById } from "src/api/blog";
import { Tab } from "bootstrap";
import { Tabs } from "react-bootstrap";
import { CContainer } from "@coreui/react";
import QueAns from "src/components/Blogs/QuesAns"
import SideBarImage from "src/components/Blogs/SideBarImage";
import WhatCustomerSays from "src/components/Blogs/WhatCustomerSays";
import PopularServiced from "src/components/Blogs/PopularServiced";
import SideBarBlogs from "src/components/Blogs/SideBarBlogs";

const BlogsPage = () => {
  const router = useLocation();
  const blogId = router?.state?.id;
  const [blogData, setBlogData] = useState();
  const [key, setKey] = useState("home");


  useEffect(() => {
    getDataById();
  }, []);

  const getDataById = () => {
    getBlogById(blogId)
      .then((res) => setBlogData(res))
      .catch((err) => console.log("err", err));
  };


  return (
    <>
      <CContainer>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="home" title="Hero Section">
            {HeroSection && <HeroSection blogPageId={blogId} />}
          </Tab>
          <Tab eventKey="tabs" title="Tabs Section">
            {TabsPage && <TabsPage />}
          </Tab>

          <Tab eventKey="qa" title="Q & A Section">
            {QueAns && <QueAns blogPageId={blogId} />}

          </Tab>
          <Tab eventKey="blogs" title="SideBar Blogs">
            {SideBarBlogs && <SideBarBlogs />}

          </Tab>
          <Tab eventKey="image" title="SideBar Image">
            {SideBarImage && <SideBarImage />}

          </Tab>
          <Tab eventKey="popularservices" title="Popular Serviced">
            {PopularServiced && <PopularServiced />}

          </Tab>
          <Tab eventKey="customersay" title="What our customers say">
            {WhatCustomerSays && <WhatCustomerSays />}

          </Tab>
        </Tabs>
      </CContainer>
    </>
  );
};

export default BlogsPage;
