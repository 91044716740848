//src>navigations>protected.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getLocalStorage } from "src/services/helper";

function Protected({Component}) { 

  const navigate = useNavigate();
  
  let userData = localStorage.getItem("token");

  useEffect(() => {
    if (!userData) {
      navigate("/login");
    }
  }, []);

  return (
    <div>
        {Component && <Component />}
    </div>
  );
}

Protected.propTypes = {
  Component: PropTypes.func.isRequired,
};

export default Protected;
