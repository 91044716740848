import request from "src/services/request";
import { API_BASE_URL } from "src/services/const";
import axiosInstance from "./axiosApi";

//Services Section
export const addServices = (data) => request(`${API_BASE_URL}/services`, {
  method: "POST",
  data,
});

export const getAllServices = () => request(`${API_BASE_URL}/services`, { method: "GET" });

export const getServices = () => {
  return request(`${API_BASE_URL}/services`, {
    method: "GET",
  })
};

export const getServicesById = (id) => request(`${API_BASE_URL}/services/${id}`, { method: "GET" });

export const updateService = (id, data) => request(`${API_BASE_URL}/services/${id}`, {
  method: "PATCH",
  data,
});

export const deleteService = (id,) => request(`${API_BASE_URL}/services/${id}`, { method: "DELETE" });

//slug-services Section
export const addServicesSlug = (data) => request(`${API_BASE_URL}/servicesStateCity`, {
  method: "POST",
  data,
});
export const updateServicesSlug = (id, data) => request(`${API_BASE_URL}/servicesStateCity/${id}`, {
  method: "PATCH",
  data,
});
export const getServicesSlug = () => request(`${API_BASE_URL}/servicesStateCity`, { method: "GET" });

export const deleteServiceSlug = (id) => request(`${API_BASE_URL}/servicesStateCity/${id}`, {
  method: "DELETE",
});


//getSubMenus
export const getSubMenu = () => request(`${API_BASE_URL}/submenu`, { method: "GET" });

// Hero Section
export const getHeroSection = () => request(`${API_BASE_URL}/hero-section`, { method: "GET" });

export const getSingleHeroSection = (id) => request(`${API_BASE_URL}/hero-section/${id}`, { method: "GET" });

export const addHeroSection = (data) => request(`${API_BASE_URL}/hero-section`, {
  method: "POST",
  data,
  headers: new Headers({
    "Content-Type": "multipart/form-data",
  }),
});

export const editHeroSection = (id, data) => request(`${API_BASE_URL}/hero-section/${id}`, {
  method: "PATCH",
  data,
  headers: new Headers({
    "Content-Type": "multipart/form-data",
  }),
});

export const deleteHeroSection = (data, id) => request(`${API_BASE_URL}/hero-section/${id}`, {
  method: "DELETE",
  data,
});

// Tabs Section
export const addTabsSection = (data) => request(`${API_BASE_URL}/register-gst`, {
  method: "POST",
  data,
});

// Question Answer
export const getQusAnsSection = () => request(`${API_BASE_URL}/questions`, {
  method: "GET",
});

export const getQusAnsSingle = (id) => request(`${API_BASE_URL}/questions/${id}`, { method: "GET" });

export const addQusAnsSection = (data) => request(`${API_BASE_URL}/questions`, {
  method: "POST",
  data,
});

export const editQusAnsSection = (id, data) => request(`${API_BASE_URL}/questions/${id}`, {
  method: "PATCH",
  data,
});

export const deleteQusAns = (id) => request(`${API_BASE_URL}/questions/${id}`, {
  method: "DELETE",
});

//Team Section
export const addTeamMember = (data) => request(`${API_BASE_URL}/ourTeam`, {
  method: "POST",
  data,
  headers: new Headers({
    "Content-Type": "multipart/form-data",
  }),
});

export const getAllTestinomials = () => request(`${API_BASE_URL}/ourTeam`, { method: "GET" });

export const getSingleTestinomial = (id) => request(`${API_BASE_URL}/ourTeam/${id}`, { method: "GET" });

export const updateTestinomial = (id, data) => request(`${API_BASE_URL}/ourTeam/${id}`, {
  method: "PATCH",
  data,
  headers: new Headers({
    "Content-Type": "multipart/form-data",
  }),
});

export const deleteTestinomial = (id) => request(`${API_BASE_URL}/ourTeam/${id}`, { method: "DELETE" });

//Tags Section
export const addTags = (data) => request(`${API_BASE_URL}/tags`, {
  method: "POST",
  data,
});

export const getAllTags = () => request(`${API_BASE_URL}/tags`, { method: "GET" });

export const getSingleTags = (id) => request(`${API_BASE_URL}/tags/${id}`, { method: "GET" });

export const updateTag = (id, data) => request(`${API_BASE_URL}/tags/${id}`, {
  method: "PATCH",
  data,
});

export const deleteTag = (id) => request(`${API_BASE_URL}/tags/${id}`, { method: "DELETE" });

//Blogs Section
export const addBlogs = (data) => request(`${API_BASE_URL}/blogs`, {
  method: "POST",
  data,
});

export const getAllBlogs = () => request(`${API_BASE_URL}/blogs`, { method: "GET" });

export const getSingleBlogs = (id) => request(`${API_BASE_URL}/blogs/${id}`, { method: "GET" });

export const updateBlog = (id, data) => request(`${API_BASE_URL}/blogs/${id}`, {
  method: "PATCH",
  data,
});

export const deleteBlog = (id) => request(`${API_BASE_URL}/blogs/${id}`, { method: "DELETE" });

//Category Section
export const addCategories = (data) => request(`${API_BASE_URL}/categories`, {
  method: "POST",
  data,
});

export const getAllCategories = () => request(`${API_BASE_URL}/categories`, { method: "GET" });

export const getSingleCategories = (id) => request(`${API_BASE_URL}/categories/${id}`, { method: "GET" });

export const updateCategory = (id, data) => request(`${API_BASE_URL}/categories/${id}`, {
  method: "PATCH",
  data,
});

export const deleteCategory = (id) => request(`${API_BASE_URL}/categories/${id}`, { method: "DELETE" });

//description
export const addDescription = (data) => request(`${API_BASE_URL}/descriptions`, {
  method: "POST",
  data,
  headers: new Headers({
    "Content-Type": "multipart/form-data",
  }),
});

export const getAllDescription = () => request(`${API_BASE_URL}/descriptions`, { method: "GET" });

export const getSingleDescription = (id) => request(`${API_BASE_URL}/descriptions/${id}`, { method: "GET" });

export const updateDescription = (id, data) => request(`${API_BASE_URL}/descriptions/${id}`, {
  method: "PATCH",
  data,
  headers: new Headers({
    "Content-Type": "multipart/form-data",
  }),
});

export const deleteDescription = (id) => request(`${API_BASE_URL}/descriptions/${id}`, { method: "DELETE" });

//GST section
export const addServiceTab = (data) => request(`${API_BASE_URL}/serviceTabs`, {
  method: "POST",
  data,
});

export const getAllGst = () => request(`${API_BASE_URL}/serviceTabs`, { method: "GET" });

export const getSingleGst = (id) => request(`${API_BASE_URL}/gst/${id}`, { method: "GET" });

export const updateServiceTab = (id, data) => request(`${API_BASE_URL}/gst/${id}`, {
  method: "PATCH",
  data,
});

export const deleteGst = (id) => request(`${API_BASE_URL}/gst/${id}`, { method: "DELETE" });


//Image Upload
export const uploadTextEditorImage = (data) => {
  const response = axiosInstance.post(`${API_BASE_URL}/uploadfile`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response;
}

export const uploadCSVFile = (data) => {
  const response = axiosInstance.post(`${API_BASE_URL}/csv`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response;
}