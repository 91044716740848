// import { provider } from "../provider";
import React, { useEffect, useState } from "react";
import {
  CFormInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CFormSelect,
  CFormLabel,
} from "@coreui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { getServices } from "src/api/service";
import { addBlogData, getBlogById, updateBlogs } from "src/api/blog";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
const PricingForm = () => {
  const route = useLocation();
  const { blogId, isUpdate } = route?.state;
  const initialState = {
    plan: "",
    keywords: "",
    cost: "",
  };

  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialState);
  const [options, setOptions] = useState();
  const [description, setdescription] = useState("");

  useEffect(() => {
    if (isUpdate) {
      getBlogsData();
    }
  }, [isUpdate]);

  useEffect(() => {
    getAllServices();
  }, []);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setdescription(data);
    // setTabSection({...tabSection,description:data})
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const [errors, setErrors] = useState({});

  const getBlogsData = () => {
    getBlogById(blogId)
      .then((res) => {
        setFormData(res?.data);
      })
      .catch((err) => console.log("Create Service Page From Get", err));
  };

  const handleSubmit = () => {
    let formIsValid = true;
    const errorsCopy = { ...errors };

    if (!plan) {
      errorsCopy.plan = "Plan Name is required";
      formIsValid = false;
    } else {
      errorsCopy.plan = "";
    }

    if (!description) {
      errorsCopy.description = "Description is required";
      formIsValid = false;
    } else {
      errorsCopy.description = "";
    }

    if (!keywords) {
      errorsCopy.keywords = "Keywords are required";
      formIsValid = false;
    } else {
      errorsCopy.keywords = "";
    }

    if (!cost) {
      errorsCopy.cost = "Monthly cost is required";
      formIsValid = false;
    } else {
      errorsCopy.cost = "";
    }

    setErrors(errorsCopy);

    if (formIsValid) {
      const payload = {
        plan,
        keywords,
        cost
      };

      if (blogId === undefined) {
        addBlogData(payload)
          .then((res) => {
            navigate(`/blogs/blog-form`, {
              state: {
                id: res?.data?.id,
                name: res?.data?.title,
              },
            });
            setFormData(initialState);
          })
          .catch((err) => console.log("Create Blog Page From Add", err));
      } else {
        updateBlogs(blogId, payload)
          .then((res) => {
            navigate(`/blogs/blog-form`, {
              state: {
                id: blogId,
                name: formData?.title,
              },
            });
          })
          .catch((err) => console.log("Create Blog Page From Update", err));
      }
    }
  };

  const [clicked, setClicked] = useState({
    plan: false,
    cost: false,
    keywords: false,
  });

  const handleInputFocus = (fieldName) => {
    setClicked({ ...clicked, [fieldName]: true });

    setErrors({ ...errors, [fieldName]: "" });
  };

  const getAllServices = () => {
    getServices().then((res) => {
      setOptions(
        res?.data?.map((services) => {
          return {
            label: services?.serviceName,
            value: services?.id,
          };
        })
      );
    });
  };

  const { plan, cost, keywords } = formData;
  const renderCategoryForm = () => {
    return (
      <CCard>
        <CCardHeader><strong>Add Price Listing</strong> </CCardHeader>
        <CCardBody>
          {/* <Container> */}
          <CForm>
            <CRow className="align-items-start mb-2">
              <CCol xs={12} md={6} lg={6}>
              <CFormLabel>Plan Name</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  name="plan"
                  value={plan}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("plan")}
                  aria-describedby="exampleFormControlInputHelpInline"
                />
                {errors.plan && (
                  <div className="text-danger">{errors.plan}</div>
                )}
              </CCol>

              <CCol xs={12} md={6} lg={6} className="mb-2">
              <CFormLabel>Monthly Cost</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  name="cost"
                  value={cost}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("cost")}
                  aria-describedby="exampleFormControlInputHelpInline"
                />
                {errors.cost && (
                  <div className="text-danger">{errors.cost}</div>
                )}
              </CCol>
            </CRow>

            <CRow className="align-items-start mb-4">
              <CCol>
                <CFormLabel>Description</CFormLabel>
                <span className="red-star">{` `}*</span>
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onChange={handleEditorChange}
                  name={description}
                  onFocus={() => handleInputFocus("description")}
                />
                {errors.description && (
                  <div className="text-danger">{errors.description}</div>
                )}
              </CCol>
            </CRow>

            <div className="btn-alg">
              <CButton onClick={handleSubmit}>Submit</CButton>
            </div>
          </CForm>
          {/* </Container> */}
        </CCardBody>
      </CCard>
    );
  };

  return renderCategoryForm();
};

export default PricingForm;
