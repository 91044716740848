import React, { useState, useEffect, useRef } from "react";
import {
  CFormInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormLabel,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import DeletePopMsg from "src/components/modal/DeletePopMsg";
import {
  addHeroSection,
  getSingleHeroSection,
  updateHeroSection,
  deleteHeroSection,
  getHeroSection,
} from "src/api/blog";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import { API_BASE_URL } from "src/services/const";
import { uploadTextEditorImage } from "src/api/service";

const initialState = {
  title: "",
};

const WhatCustomerSays = (pageId) => {
  const blogId = pageId.blogPageId;
  const [heroSection, setHeroSection] = useState(initialState);
  const [blogData, setBlogData] = useState([]);
  const [description, setdescription] = useState("");
  const [heroAction, setHeroAction] = useState("create");
  const [heroId, setHeroId] = useState("");
  const [visible, setVisible] = useState(false);

  const ref = useRef(null);
  const imageRef = useRef();

  useEffect(() => {
    getHeroSectionData();
  }, []);

  const getHeroSectionData = () => {
    getHeroSection()
      .then((res) => setBlogData(res?.data))
      .catch((err) => alert(err));
  };

  const blogDataById = blogData?.filter(
    (b) => b.blogPageId === parseInt(blogId)
  );

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setHeroSection({ ...heroSection, [name]: value });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setdescription(data);
  };

  const handleHeroSection = async () => {
    const { title } = heroSection;
    // const cleanDescription = (desc) => {
    //   let temp = desc.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
    //   temp = temp.replace(/&nbsp;/g, " "); // Replace &nbsp; with space
    //   temp = temp.replace(/&amp;/g, "&"); // Replace &amp; with &
    //   temp = temp.replace(/&lt;/g, "<"); // Replace &lt; with <
    //   temp = temp.replace(/&gt;/g, ">"); // Replace &gt; with >
    //   return temp;
    // };

    // let descriptions = cleanDescription(description);

    const formData = new FormData();
    formData.append("blogPageId", blogId);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("image", imageRef?.current?.files[0]);

    if (heroAction === "create") {
      addHeroSection(formData)
        .then((res) => {
          getHeroSectionData();
          setdescription("");
          setHeroSection(initialState);
          setHeroAction("create");
        })
        .catch((err) => console.log("err", err));
    } else {
      for (let pair of formData.entries()) { }
      // updateHeroSection(heroId, formData)
      const updateData = await axios
        .patch(`${API_BASE_URL}/blog-page-hero-section/${heroId}`, formData)
        .then((res) => {
          setdescription("");
          setHeroSection(initialState);
          setHeroAction("create");
        })
        .catch((err) => console.log("err", err));
    }
  };

  //get Single HeroSection
  const getSingleHeroData = (id) => {
    setHeroSection({ ...heroSection, blogPageId: id });
    getSingleHeroSection(id)
      .then((res) => {
        setHeroSection(res.data);
        setdescription(res.data.description);
      })
      .catch((err) => console.log("err", err));
  };

  //delete Hero Section
  const deleteHero = (id) => {
    deleteHeroSection(id)
      .then((res) => setVisible(false))
      .catch((err) => console.log("err", err));
  };

  const scrollElement = () => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const { title } = heroSection;
  const editorConfiguration = {
    extraPlugins: [MyCustomUploadAdapterPlugin] // Register the custom plugin
  };

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            const formData = new FormData();
            formData.append("file", file);
            uploadTextEditorImage(formData)
              .then((response) => {
                resolve({ default: response.data.data.Location });
              })
              .catch((error) => {
                console.error("Error uploading image:", error);
                reject(error);
              });
          });
        });
      },
    };
  };
  return (
    <CCard>
      <CCardBody>
        <CTable responsive className="table table-bordered">
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">S.No.</CTableHeaderCell>
              <CTableHeaderCell scope="col">Name</CTableHeaderCell>
              <CTableHeaderCell scope="col">Description</CTableHeaderCell>


              <CTableHeaderCell scope="col">Action</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {blogDataById?.length > 0 &&
              blogDataById?.map((item, index) => {
                return (
                  <CTableRow key={index}>
                    <CTableDataCell>{index + 1}</CTableDataCell>
                    <CTableDataCell>{item?.title}</CTableDataCell>
                    <CTableDataCell>{item?.image}</CTableDataCell>
                    <CTableDataCell>
                      <>
                        <Tooltip text="Update">
                          <FontAwesomeIcon
                            role="button"
                            icon={faPenToSquare}
                            size="1x"
                            className="px-3 updateIcon"
                            onClick={() => {
                              setHeroAction("edit");
                              setHeroId(item?.id);
                              getSingleHeroData(item?.id);
                              scrollElement();
                            }}
                          />
                        </Tooltip>

                        <Tooltip text="Delete">
                          <FontAwesomeIcon
                            onClick={() => {
                              setHeroAction("create");
                              setHeroId(item?.id);
                              setVisible(true);
                            }}
                            role="button"
                            icon={faTrash}
                            size="1x"
                            className="deleteIcon"
                          />
                        </Tooltip>
                      </>
                    </CTableDataCell>
                  </CTableRow>
                );
              })}
          </CTableBody>
        </CTable>
      </CCardBody>
      <br />
      <CCardHeader ref={ref}>
        {heroAction === "create"
          ? " Create What Customer Says"
          : heroAction === "edit"
            ? "Edit What Customer Says"
            : ""}
      </CCardHeader>
      <CCardBody>
        <CForm>
          <CRow className="align-items-start">
            <CCol xs={12} md={6} lg={6}>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                label="Name"
                name="title"
                value={title}
                onChange={handleChangeValue}
                here
                aria-describedby="exampleFormControlInputHelpInline"
              />
            </CCol>

          </CRow>
          <br />
          <CRow>
            <CCol>
              <CFormLabel>Description</CFormLabel>
              <CKEditor
                editor={ClassicEditor}
                data={description}
                onChange={handleEditorChange}
                config={editorConfiguration}

              />
            </CCol>
          </CRow>
          <br />
          <CButton onClick={handleHeroSection}>Submit</CButton>
        </CForm>
      </CCardBody>

      <DeletePopMsg
        visible={visible}
        setVisible={setVisible}
        id={heroId}
        deleteFunction={deleteHero}
      />
    </CCard>
  );
};

export default WhatCustomerSays;
