import request from "src/services/request";
import { API_BASE_URL } from "src/services/const";

export const addBlogQusAnsSection = (data) => request(`${API_BASE_URL}/blogquestions`, { method: "POST", data });

export const getBlogQusAnsSection = () => request(`${API_BASE_URL}/blogquestions`, { method: "GET" });

export const getBlogQusAnsSingle = (id) => request(`${API_BASE_URL}/blogquestions/${id}`, { method: "GET" });

export const editBlogQusAnsSection = (id, data) => request(`${API_BASE_URL}/blogquestions/${id}`, { method: "PATCH", data });

export const deleteBlogQusAns = (id) => request(`${API_BASE_URL}/blogquestions/${id}`, { method: "DELETE" });