import React, { useState, useEffect, useRef } from "react";
import {
  CFormInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import DeletePopMsg from "src/components/modal/DeletePopMsg";
import {
  getQusAnsSection,
  getQusAnsSingle,
  addQusAnsSection,
  editQusAnsSection,
  deleteQusAns,
} from "src/api/service";
import { useLocation } from "react-router-dom";

export default function PopularServiced(questionDatabyID) {
  const router = useLocation();
  const queAns = {
    question: "",
    answer: "",
    serviceId: router?.state?.id,
  };
  const [queAnsAction, setQueAnsAction] = useState("create");
  const [queAnsId, setQueAnsId] = useState("");
  const [queAnsSection, setQueAnsSection] = useState(queAns);
  const [queAnsData, setQueAnsData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [serviceName, setServiceName] = useState(router.state.name);
  const ref = useRef(null);
  useEffect(() => {
    getAllQueAns();
  }, []);

  const handleQueAns = (e) => {
    const { name, value } = e.target;
    setQueAnsSection({ ...queAnsSection, [name]: value });
  };
  const handleQueAnsSection = () => {
    const payload = {
      question: queAnsSection.question,
      answer: queAnsSection.answer,
      serviceId: router?.state?.id,
    };
    if (queAnsAction === "create") {
      addQusAnsSection(payload)
        .then((res) => {
          getAllQueAns();

          setQueAnsSection(queAns);
          setQueAnsAction("create");
        })
        .catch((err) => console.log("err", err));
    } else {
      editQusAnsSection(queAnsId, payload)
        .then((res) => {
          getAllQueAns();

          setQueAnsSection(queAns);
          setQueAnsAction("create");
        })
        .catch((err) => console.log("err", err));
    }
  };

  const getSingleQueAns = (id) => {
    setQueAnsSection({ ...queAnsSection, serviceId: id });
    getQusAnsSingle(id)
      .then((res) => {
        setQueAnsSection(res.data);
      })
      .catch((err) => console.log("err", err));
  };
  //get All QueAns
  const getAllQueAns = () => {
    getQusAnsSection()
      .then((res) => {
        setQueAnsData(res?.data);
      })
      .catch((err) => console.log("err", err));
  };
  //delete Testinomial
  const deleteData = (id) => {
    deleteQusAns(id)
      .then((res) => {
        getAllQueAns();

        setVisible(false);
      })
      .catch((err) => console.log("err", err));
  };

  return (
    <CCard>
      <CCardHeader>
        <strong>Popular Services</strong>
      </CCardHeader>
      <CCardBody>
        <CTable responsive className="table table-bordered">
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">S.No.</CTableHeaderCell>
              <CTableHeaderCell scope="col">Service Name</CTableHeaderCell>
              <CTableHeaderCell scope="col">Action</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {questionDatabyID?.questionDatabyID?.length > 0 &&
              questionDatabyID?.questionDatabyID?.map((item, index) => {
                return (
                  <CTableRow key={index}>
                    {/* <CTableDataCell>{item.id || "-"}</CTableDataCell> */}
                    <CTableDataCell>{index + 1}</CTableDataCell>
                    <CTableDataCell>{serviceName}</CTableDataCell>
                    <CTableDataCell>{item?.question}</CTableDataCell>

                    <CTableDataCell>
                      <>
                        <Tooltip text="Update">
                          <FontAwesomeIcon
                            role="button"
                            icon={faPenToSquare}
                            size="1x"
                            className="px-3 updateIcon"
                            onClick={() => {
                              setQueAnsAction("edit");
                              setQueAnsId(item?.id);
                              getSingleQueAns(item?.id);
                            }}
                          />
                        </Tooltip>

                        <Tooltip text="Delete">
                          <FontAwesomeIcon
                            onClick={() => {
                              setQueAnsAction("create");
                              setQueAnsId(item?.id);
                              setVisible(true);
                            }}
                            role="button"
                            icon={faTrash}
                            size="1x"
                            className="deleteIcon"
                          />
                        </Tooltip>
                      </>
                    </CTableDataCell>
                  </CTableRow>
                );
              })}

            {/* ))} */}
          </CTableBody>
        </CTable>
      </CCardBody>
      <br />

      <CCardBody>
        {/* <Container> */}
        <CForm>
          <CRow className="align-items-start">
            <CCol>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                label="Service Name"
                name="question"
                value={queAnsSection.question}
                onChange={handleQueAns}
                aria-describedby="exampleFormControlInputHelpInline"
              />
            </CCol>
            {/* <CCol>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                label="Answer"
                name="answer"
                value={queAnsSection.answer}
                onChange={handleQueAns}
                aria-describedby="exampleFormControlInputHelpInline"
              />
            </CCol> */}
          </CRow>
          <br />
          {/* <CRow className="align-items-start">
            <CCol>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                label="Service Name"
                value={serviceName ? serviceName : router.state.name}
                disabled
                aria-describedby="exampleFormControlInputHelpInline"
              />
            </CCol>
          </CRow> */}
          <br />

          <br />
          <CButton onClick={handleQueAnsSection}>Submit</CButton>
        </CForm>
        {/* </Container> */}
      </CCardBody>

      <DeletePopMsg
        visible={visible}
        setVisible={setVisible}
        id={queAnsId}
        deleteFunction={deleteData}
      />
    </CCard>
  );
}
