import React, { useState, useEffect } from "react";
import { CFormInput, CForm, CButton, CCard, CCardBody, CCardHeader, CRow, CCol, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow, CFormSelect, CFormLabel } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import {
  // getServicesSlug,
  addServicesSlug,
  deleteServiceSlug,
  // updateServicesSlug,
  getAllServices, getServicesSlug, updateServicesSlug,
} from "src/api/service";
import { useLocation } from "react-router-dom";
import DeletePopMsg from "src/components/modal/DeletePopMsg";

export default function SlugUrl() {
  const router = useLocation();

  const slugObj = {
    service_name: "",
    slugUrl: "",
    serviceId: "",
    isState: ""
  };

  const [slugAction, setSlugAction] = useState("create");
  const [slugID, setSlugID] = useState("");
  const [slugSection, setSlugSection] = useState(slugObj);
  const [visible, setVisible] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const [service_slugUrl, setService_slugUrl] = useState();
  const [serviceStateData, setServiceStateData] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    getAllServiceData();
    getServicesStateCity();
  }, []);

  const getServicesStateCity = () => {
    getServicesSlug()
      .then(res => setServiceStateData(res.data))
      .catch(err => alert("Server Error", err))
  }

  const getAllServiceData = () => {
    getAllServices()
      .then(res => setServiceData(res?.data))
      .catch((e) => console.log("error get all services", e))
  };

  // const handleSelectedService = (e) => {
  //   const { name, value } = e.target;
  //   setSlugSection({ ...slugSection, [name]: value });
  //   fetchSlugUrl(e.target.value)
  // };
  const handleSelectedService = (selectedService) => {
    setSearchInput(selectedService);
    const selectedServiceData = serviceData.find(service => service.serviceName === selectedService);
    if (selectedServiceData) {
      setSlugSection({ ...slugSection, service_name: selectedServiceData.serviceName, slugUrl: selectedServiceData.serviceSlugUrl });
      setService_slugUrl(selectedServiceData.serviceSlugUrl);
    } else {
      setSlugSection({ ...slugSection, service_name: "", slugUrl: "" });
      setService_slugUrl("");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (slugAction === "edit" && name === "SlugUrl" && !value) {
      setSlugSection({ ...slugSection, [name]: service_slugUrl });
    } else {
      setSlugSection({ ...slugSection, [name]: value });
    }
  };
  // riya code
  // const handleChange = (e) => {
  //   setSearchInput(e.target.value); // Update search input state
  //   setSlugSection({ ...slugSection, service_name: "" }); // Clear service_name
  //   setService_slugUrl(""); // Clear slugUrl
  // };

  const fetchSlugUrl = (serviceName) => {
    const filteredServices = serviceData?.filter((item) => item?.serviceName == serviceName);
    setService_slugUrl(filteredServices[0]?.serviceSlugUrl);
  };


  const handleSubmitSlug = () => {
    const payload = {
      service_name: slugSection?.service_name,
      slugUrl: service_slugUrl,
      serviceId: router?.state?.id,
      isState: slugSection.isState
    };

    if (slugAction === "create") {
      addServicesSlug(payload)
        .then((res) => {
          setSlugSection(slugObj);
          setService_slugUrl()
          getServicesStateCity();
          setSlugAction("create");
        })
        .catch((err) => alert("error in add services slug", err));
    } else {
      updateServicesSlug(payload)
        .then((res) => {
          getServicesStateCity();
          setSlugSection(slugObj);
          setService_slugUrl()
          setSlugAction("update");
        })
        .catch((err) => alert("err update", err));
    }
  };

  const deleteServiceSlugDetails = (id) => {
    deleteServiceSlug(id)
      .then((res) => {
        getAllServiceData();
        getServicesStateCity();
        setVisible(false);
      })
      .catch((err) => alert("err", err));
  };

  return (
    <CCard>
      <CCardHeader>
        <strong>Slug URL</strong>
      </CCardHeader>
      <CCardBody>
        {/* <Container> */}
        <CForm>
          <CRow className="align-items-start">
            <CCol>
              <CFormInput
                type="text"
                id="searchInput"
                label="Service Name"
                placeholder="Search or Type Service Name"
                value={searchInput}
                onChange={(e) => handleSelectedService(e.target.value)}
                list="serviceList"
              />
              {/* Datalist for service suggestions */}
              <datalist id="serviceList">
                {serviceData.map((item, index) => (
                  <option key={index} value={item.serviceName} />
                ))}
              </datalist>
            </CCol>
            <CCol>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                label="Slug URL"
                name="SlugUrl"
                value={slugSection.slugUrl || service_slugUrl || ''}
                onChange={handleChange}
                disabled
                aria-describedby="exampleFormControlInputHelpInline"
              />
            </CCol>
          </CRow>
          <br />
          <CRow>
            <CCol xs={12}>
              <CFormLabel>Sub Menu Visible</CFormLabel>
              <CFormSelect
                aria-label="Parent"
                name="isState"
                value={slugSection.isState}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Open this Select Menu
                </option>
                <option value={true}>True</option>
                <option value={false}>False</option>
              </CFormSelect>
            </CCol>
          </CRow>

          <br />
          <br />
          <CButton onClick={handleSubmitSlug}>Submit</CButton>
        </CForm>
      </CCardBody>

      <CCardBody>
        <CTable responsive className="table table-bordered">
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">S.No.</CTableHeaderCell>
              <CTableHeaderCell scope="col">Service Name</CTableHeaderCell>
              <CTableHeaderCell scope="col">Slug URL</CTableHeaderCell>
              <CTableHeaderCell scope="col">Action</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {serviceStateData?.length > 0 &&
              serviceStateData?.filter(serviceFilter => serviceFilter.serviceId == router?.state?.id)
                .map((item, index) => {
                  return (
                    <CTableRow key={index}>
                      <CTableDataCell>{index + 1}</CTableDataCell>
                      <CTableDataCell>{item.service_name}</CTableDataCell>
                      <CTableDataCell>
                        {item.slugUrl}
                      </CTableDataCell>
                      <CTableDataCell>
                        <>
                          <Tooltip text="Update">
                            <FontAwesomeIcon
                              role="button"
                              icon={faPenToSquare}
                              size="1x"
                              className="px-3 updateIcon"
                              onClick={() => {
                                setSlugAction("edit");
                                setSlugSection(item);
                              }}
                            />
                          </Tooltip>

                          <Tooltip text="Delete">
                            <FontAwesomeIcon
                              onClick={() => {
                                setSlugAction("create");
                                setSlugID(item?.id);
                                setVisible(true);
                              }}
                              role="button"
                              icon={faTrash}
                              size="1x"
                              className="deleteIcon"
                            />
                          </Tooltip>
                        </>
                      </CTableDataCell>
                    </CTableRow>
                  );
                })}

            {/* ))} */}
          </CTableBody>
        </CTable>
      </CCardBody>
      <br />


      <DeletePopMsg
        visible={visible}
        setVisible={setVisible}
        id={slugID}
        deleteFunction={deleteServiceSlugDetails}
      />
    </CCard>
  );
}
