// import { provider } from "../provider";
import React, { useEffect, useState } from "react";
import {
  CFormInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CFormSelect,
  CFormLabel,
  CFormTextarea,
} from "@coreui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllServices } from "src/api/service";
import { addBlogData, getBlogById, updateBlogs } from "src/api/blog";

const initialState = {
  serviceId: "",
  metaDescription: "",
  metaTitle: "",
  slugUrl: "",
  title: "",
  metaKeyword: "",
};


const ViewBlogDetails = () => {

  const route = useLocation();
  const { blogId, isUpdate } = route?.state;
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialState);
  const [options, setOptions] = useState();
  const [selectedService, setSelectedService] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isUpdate) {
      getBlogsData();
    }
  }, [isUpdate]);

  useEffect(() => {
    getAllServicesForBlog();
  }, []);

  const [clicked, setClicked] = useState({
    serviceId: false,
    title: false,
    subtitle: false,
    slugUrl: false,
    metaDescription: false,
    metaTitle: false,
    metaKeyword: false,
    image: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const getBlogsData = () => {
    getBlogById(blogId)
      .then((res) => {
        setFormData(res?.data);
      })
      .catch((err) => console.log("Create Service Page From Get", err));
  };

  const handleInputFocus = (fieldName) => {
    setClicked({ ...clicked, [fieldName]: true });

    setErrors({ ...errors, [fieldName]: "" });
  };

  const getAllServicesForBlog = () => {
    getAllServices().then((res) => {
      setOptions(
        res?.data?.map((services) => {
          return {
            label: services?.serviceName,
            value: services?.id,
          };
        })
      );
    });
  };

  const { serviceId, metaDescription, metaTitle, slugUrl, title, metaKeyword } = formData;

  return (
    <CCard className="mb-4">
      <CCardHeader><strong>Blogs Details</strong></CCardHeader>
      <CCardBody>
        <CForm>
          <CRow>
            <CCol xs={12} className="marMobile">
              <CFormLabel><strong>Service Name :</strong></CFormLabel>
              {options?.map((optionData, index) => {
                if (index == serviceId)
                  return (<>
                    {/* //how to write it in text???                      */}
                  </>
                  );
              })}

            </CCol>
            <CCol xs={12} >
              <CFormLabel><strong>Blog Title :</strong></CFormLabel> {` ${title}`}
            </CCol>
            <CCol xs={12} >
              <CFormLabel><strong>Slug URL :</strong></CFormLabel> {` ${slugUrl}`}
            </CCol>
            <CCol xs={12} md={6} lg={6} className="marMobile">
              <CFormLabel><strong>Meta Title :</strong></CFormLabel> {` ${metaTitle}`}
            </CCol>
            <CCol xs={12} >
              <CFormLabel><strong>Meta Keywords :</strong></CFormLabel> {` ${metaKeyword}`}
            </CCol>
            <CCol xs={12} className="marMobile mb-4">
              <CFormLabel><strong>Meta Description :</strong></CFormLabel>  {` ${metaDescription}`}
            </CCol>
          </CRow>
        </CForm>
      </CCardBody>
    </CCard>
  );
};

export default ViewBlogDetails;
