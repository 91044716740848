// import { provider } from "../provider";
import React, { useEffect, useState } from "react";
import {
  CFormInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CFormSelect,
  CFormLabel,
} from "@coreui/react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addServices,
  getServicesById,
  updateService,
  getSubMenu,
} from "src/api/service";

const ViewServices = () => {

  const route = useLocation();
  const { servicePageId, isUpdate, serviceDataName } = route?.state;

  const initialState = {
    serviceName: "",
    submenuId: "",
    serviceMetaDescription: "",
    serviceMetaTitle: "",
    serviceSlugUrl: "",
    serviceMetaKeyword: "",
    isVisibleSubMenu: "",
  };

  const [clicked, setClicked] = useState({
    serviceName: false,
    serviceSlugUrl: false,
    serviceMetaDescription: false,
    serviceMetaTitle: false,
    serviceMetaKeyword: false,
    isVisibleSubMenu: false,
    submenuId: false,
  });

  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialState);
  const [options, setOption] = useState(null);

  useEffect(() => {
    if (isUpdate) {
      getServicesData();
    }
  }, [isUpdate]);

  useEffect(() => {
    getSubMenus();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const getServicesData = () => {
    getServicesById(servicePageId)
      .then((res) => {
        setFormData(res?.data[0])
      })
      .catch((err) => console.log("Create Service Page From Get", err));
  };

  const getSubMenus = () => {
    getSubMenu()
      .then((res) => {
        setOption(
          res.data.map((menu) => {
            return {
              label: menu.submenuName,
              value: menu.id,
            };
          })
        );
      })
      .catch((err) => { });
  };


  const handleInputFocus = (fieldName) => {
    setClicked({ ...clicked, [fieldName]: true });

    setErrors({ ...errors, [fieldName]: "" });
  };

  // const handleEditorChange = (event, editor) => {
  //   const data = editor.getData();
  //   setdescription(data);
  //   // setTabSection({...tabSection,description:data})
  // };


  const {
    serviceName,
    submenuId,
    serviceMetaDescription,
    serviceMetaTitle,
    serviceSlugUrl,
    serviceMetaKeyword,
    isVisibleSubMenu,
    serviceTitle
  } = formData;

  const renderCategoryForm = () => {
    return (
      <CCard className="mb-4">
        <CCardHeader><strong>Opened {serviceName}</strong></CCardHeader>
        <CCardBody>
          <CForm>
            <CRow>
              <CCol xs={12} >
                <CFormLabel><strong>Sub-Menu Name :</strong></CFormLabel> {` ${submenuId}`}
              </CCol>
              <CCol xs={12} >
                <CFormLabel><strong>Service Name :</strong></CFormLabel> {` ${serviceName}`}
              </CCol>
              <CCol xs={12} >
                <CFormLabel><strong>Slug URL :</strong></CFormLabel> {` ${serviceSlugUrl}`}
              </CCol>
              <CCol xs={12}>
                <CFormLabel><strong>SubMenu Visible(Yes/No) :</strong></CFormLabel> {isVisibleSubMenu === true ? `Yes` : `No`}
              </CCol>
              <CCol xs={12}>
                <CFormLabel><strong>Meta Title :</strong></CFormLabel> {` ${serviceMetaTitle}`}
              </CCol>
              <CCol xs={12}>
                <CFormLabel><strong>Meta Keywords :</strong></CFormLabel> {` ${serviceMetaKeyword}`}
              </CCol>
              <CCol xs={12} className="marMobile mb-4">
                <CFormLabel><strong>Meta Description :</strong></CFormLabel>  {` ${serviceMetaDescription}`}
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
    );
  };

  return renderCategoryForm();
};

export default ViewServices;
