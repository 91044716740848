import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import routes from "../navigations/AppRoutes";

import { CBreadcrumb, CBreadcrumbItem, CButton } from "@coreui/react";
import UserPopForm from "../components/modal/UserPopForm";

const AppBreadcrumb = () => {
  const currentLocation = useLocation().pathname;

  const getRouteName = (pathname, routes) => {
    const currentRoute = routes.find((route) => route.path === pathname);
    return currentRoute ? currentRoute.name : false;
  };

  const getBreadcrumbs = (location) => {
    const breadcrumbs = [];
    location.split("/").reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`;
      const routeName = getRouteName(currentPathname, routes);
      routeName &&
        breadcrumbs.push({
          pathname: currentPathname,
          name: routeName,
          active: index + 1 === array.length ? true : false,
        });
      return currentPathname;
    });
    return breadcrumbs;
  };

  const breadcrumbs = getBreadcrumbs(currentLocation);

  // add user

  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <>
      <CBreadcrumb className="m-0 ms-2 d-flex justify-content-between">
        <CBreadcrumbItem href="/">Home</CBreadcrumbItem>
        {breadcrumbs.map((breadcrumb, index) => {
          return (
            <CBreadcrumbItem
              {...(breadcrumb.active
                ? { active: true }
                : { href: breadcrumb.pathname })}
              key={index}
            >
              {breadcrumb.name}
            </CBreadcrumbItem>
          );
        })}
      </CBreadcrumb>
      {/* <div className="d-grid gap-2 col-1">
        <CButton onClick={toggleForm} className="w-5">
          Add
        </CButton>
        {showForm ? <UserPopForm /> : null}
      </div> */}
    </>
  );
};

export default React.memo(AppBreadcrumb);
