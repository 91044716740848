// import { provider } from "../provider";
import React, { useEffect, useState } from "react";
import {
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton,
    CCard,
    CCardHeader,
    CCardBody,
    Button,
    CFormInput,
    CFormSelect
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEye,
    faPenToSquare,
    faSearch,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import { Link } from "react-router-dom";
// import { deleteService } from "../../../api/service";
import DeletePopMsg from "src/components/modal/DeletePopMsg";
import ReactPaginate from "react-js-pagination";
import { getServices } from "src/api/service";


const ServiceList = () => {
    const [visible, setVisible] = useState(false);
    const [serviceId, setServiceId] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState("");

    const serviceData = [
        {
            serviceName: "anjani creations",
            serviceMetaDescription: "anjani creations limited",
            serviceMetaTitle: "anjani creations",
            serviceSlugUrl: "anjanicreations.com"

        }
    ]


    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    // const currentRows = data?.servicesData?.data?.slice(
    //   indexOfFirstRow,
    //   indexOfLastRow
    // );

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    return (
        <div>
            <CCard>
                <CCardHeader>
                    <strong>Service List</strong>
                    {/* <Link to="/services/service/add" state={{ isUpdate: false }}>
                        <CButton className="float-end btn-addbtn">Add</CButton>
                    </Link> */}
                </CCardHeader>
                {/* top start */}
                <div className="search_bar">
                    {/* div 1 started */}
                    <div className="search-inner">
                        {/* {showSelectAndSearch && ( */}
                        <div>
                            <CFormSelect
                                size="sm"
                                className="mb-3"
                                style={{ cursor: "pointer" }}
                                aria-label="Small select example"
                                onChange={(e) => setRowsPerPage(e.target.value)}
                            >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                            </CFormSelect>
                        </div>
                        {/* )} */}
                        {/* search div */}
                        <div style={{ display: "flex" }}>
                            {/* {showSelectAndSearch && ( */}
                            <>
                                <CFormInput
                                    type="text"
                                    size="sm"
                                    placeholder="Search"
                                    aria-label="sm input example"
                                    style={{ margin: "0px 0px 16px 0px" }}
                                    onChange={handleSearchChange}
                                    value={searchInput}
                                />
                                <span
                                    style={{
                                        border: " 1px solid #d1c5c5",
                                        borderRadius: "4px",
                                        margin: "0px 5px 16px -1px",
                                        padding: "1px 8px 0px 8px",
                                        background: "#ebedef",
                                        cursor: "pointer",
                                    }}
                                >
                                    <FontAwesomeIcon
                                        role="button"
                                        icon={faSearch}
                                        size="1x"
                                    />
                                </span>
                            </>
                            {/* )} */}
                        </div>
                    </div>
                    {/* div 1 ended */}

                    {/* both btn div */}
                    {/* <div className="btn_add mt-0 mb-14">
          {showAddButton && (
            <Button
              className="add-btn w-auto plr-30 mr-10 "
              onClick={handleShowAddForm}
            >
              Add
            </Button>
          )}
          {showAddButton && (
            <Button className="add-btn m-0 w-auto" onClick={download_btn}>
              Download
            </Button>
          )}
        </div> */}
                </div>
                {/* top end */}
                <CCardBody>
                    {/* <Container isLoading={isLoading}> */}
                    <CTable responsive striped className="table table-bordered">
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell className="col-1" scope="col">S.No.</CTableHeaderCell>
                                <CTableHeaderCell className="col-2" scope="col">Title</CTableHeaderCell>
                                <CTableHeaderCell className="col-3" scope="col">Meta Description</CTableHeaderCell>
                                <CTableHeaderCell className="col-3" scope="col">Meta Title</CTableHeaderCell>
                                <CTableHeaderCell className="col-2" scope="col">Slug URL</CTableHeaderCell>
                                {/* <CTableHeaderCell className="col-2" scope="col">Action</CTableHeaderCell> */}
                            </CTableRow>
                        </CTableHead>

                        <CTableBody>
                            {serviceData?.map((item, index) => (
                                <CTableRow key={index}>
                                    {/* <CTableDataCell>{item.id || "-"}</CTableDataCell> */}
                                    <CTableDataCell>{index + 1}</CTableDataCell>
                                    <CTableDataCell>{item.serviceName || "-"}</CTableDataCell>
                                    <CTableDataCell>{item.serviceMetaDescription || "-"} </CTableDataCell>
                                    <CTableDataCell>{item.serviceMetaTitle || "-"}</CTableDataCell>
                                    <CTableDataCell>{item.serviceSlugUrl || "-"}</CTableDataCell>
                                    {/* <CTableDataCell>
                                        <>
                                            <Tooltip text="View">
                                                <FontAwesomeIcon
                                                    role="button"
                                                    icon={faEye}
                                                    size="1x"
                                                    className="viewIcon"
                                                />
                                            </Tooltip>
                                            <Tooltip text="Update">
                                                <Link
                                                    to="/services/service/add"
                                                    state={{
                                                        servicePageId: item.id,
                                                        isUpdate: true,
                                                        serviceDataName: item.serviceName,
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        role="button"
                                                        icon={faPenToSquare}
                                                        size="1x"
                                                        className="px-3 updateIcon"
                                                    />
                                                </Link> */}
                                    {/* {updateForm ? <p>Update</p> : ''} */}
                                    {/* </Tooltip>
                                            <Tooltip text="Delete">
                                                <FontAwesomeIcon
                                                    onClick={() => {
                                                        setServiceId(item.id);
                                                        setVisible(true);
                                                    }}
                                                    role="button"
                                                    icon={faTrash}
                                                    size="1x"
                                                    className="deleteIcon"
                                                /> */}
                                    {/* {showForm ? <p>Update</p> : ''} */}
                                    {/* </Tooltip>
                                        </>
                                    </CTableDataCell> */}
                                </CTableRow>
                            ))}
                        </CTableBody>


                    </CTable>
                    {/* <div className="pagin-div">
          <div>
            Showing {indexOfFirstRow + 1} to{" "}
            {Math?.min(indexOfLastRow, data?.servicesData?.data?.length)} of{" "}
            {data?.servicesData?.data?.length} entries
          </div>
          <div className="pagination-container">
            <ReactPaginate
              activePage={currentPage}
              itemsCountPerPage={rowsPerPage}
              totalItemsCount={data?.servicesData?.data?.length}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              prevPageText="Previous"
              nextPageText="Next"
              firstPageText="First"
              lastPageText="Last"
              innerClass="pagination"
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        </div> */}
                </CCardBody>
                {/* <DeletePopMsg
        visible={visible}
        setVisible={setVisible}
        id={serviceId}
        deleteFunction={deleteServices}
      /> */}
            </CCard>
        </div>
    )
}

export default ServiceList
