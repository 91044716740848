import React, { useEffect, useState } from "react";
import {
  CModal,
  CButton,
  CModalHeader,
  CRow,
  CModalTitle,
  CModalBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CCard,
  CFormSelect,
} from "@coreui/react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faLock,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { addUser } from "src/api/loginApi";
import { useNavigate } from "react-router-dom";


const UserPopForm = (dataprops) => {
  const { userData, showPassword } = dataprops;

  useEffect(() => {
    if (userData) {
      setFormData(userData);
    }
  }, [])


  const [visible, setVisible] = useState(true);

  const initialState = {
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    password: "",
  }

  const [clicked, setClicked] = useState({
    first_name: false,
    last_name: false,
    mobile: false,
    email: false,
    password: false,
  });

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [refresh, setRefresh] = useState(false);

  const { first_name, last_name, mobile, email, password } = formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleInputFocus = (fieldName) => {
    setClicked({ ...clicked, [fieldName]: true });

    setErrors({ ...errors, [fieldName]: "" });
  };

  const navigate = useNavigate();


  const handleSubmitUser = (data) => {

    let formIsValid = true;
    const errorsCopy = { ...errors };

    if (!first_name) {
      errorsCopy.first_name = "First Name is required";
      formIsValid = false;
    } else {
      errorsCopy.first_name = "";
    }
    if (!last_name) {
      errorsCopy.last_name = "Last Name is required";
      formIsValid = false;
    } else {
      errorsCopy.last_name = "";
    }
    if (!mobile) {
      errorsCopy.mobile = "Mobile Number is required";
      formIsValid = false;
    } else {
      errorsCopy.mobile = "";
    }
    if (!email) {
      errorsCopy.email = "E-mail Address is required";
      formIsValid = false;
    } else {
      errorsCopy.email = "";
    }
    if (!password) {
      errorsCopy.password = "Password is required";
      formIsValid = false;
    } else {
      errorsCopy.password = "";
    }

    setErrors(errorsCopy);

    if (formIsValid) {
      const payload = {
        first_name,
        last_name,
        mobile,
        email,
        password: password
      };

      if (payload) {
        addUser(payload)
          .then((res) => {
            setVisible(false);
            // navigate("/users")
            setRefresh(!refresh);
            // getHeader();
            // setStateChange(!stateChange);
            // setHeaderSection(header);

            // setHeaderAction("create");
          })
          .catch((err) => console.log("err user not added", err));
      } else {
        // updateHeader(headerId, payload)
        // .then((res) => {
        // getHeader();
        // setStateChange(!stateChange);
        // setHeaderSection(header);
        // setHeaderAction("create");
        // })
        // .catch((err) => console.log("err", err));
      }
    }

  };

  return (
    <>
      <CModal
        backdrop="static"
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="StaticBackdropExampleLabel"
      >
        <CModalHeader>
          <CModalTitle id="StaticBackdropExampleLabel">Add User</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer>
            <CRow className="justify-content-center align-center">
              <CCol md={12}>
                <CCard className="p-4">
                  <CForm >
                    <CCol className="mb-1" md={12}>
                      <label htmlFor="first_name" className="mb-1">
                        First Name
                        <span className="red-star">{` `}*</span>
                      </label>
                      <CInputGroup className="d-flex col-md-12">
                        <CInputGroupText>
                          <FontAwesomeIcon icon={faUser} />
                        </CInputGroupText>
                        <CFormInput
                          type="text"
                          placeholder="First Name"
                          autoComplete="off"
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleInputChange}
                          onFocus={() => handleInputFocus("first_name")}
                        />
                      </CInputGroup>
                      {errors.first_name && (
                        <div className="text-danger">{errors.first_name}</div>
                      )}
                    </CCol>


                    <CCol className="mb-1" md={12}>
                      <label htmlFor="last_name" className="mb-1">
                        Last Name
                        <span className="red-star">{` `}*</span>
                      </label>
                      <CInputGroup className="d-flex col-12">
                        <CInputGroupText>
                          <FontAwesomeIcon icon={faUser} />
                        </CInputGroupText>
                        <CFormInput
                          type="text"
                          placeholder="Last Name"
                          autoComplete="off"
                          name="last_name"
                          value={last_name}
                          onChange={handleInputChange}
                          onFocus={() => handleInputFocus("last_name")}
                        />
                      </CInputGroup>
                      {errors.last_name && (
                        <div className="text-danger">{errors.last_name}</div>
                      )}
                    </CCol>

                    <CCol className="mb-1" md={12}>
                      <label htmlFor="PhoneNumber" className="mb-1">
                        Mobile Number
                        <span className="red-star">{` `}*</span>
                      </label>
                      <CInputGroup className="">
                        <CInputGroupText>
                          <FontAwesomeIcon icon={faPhone} />
                        </CInputGroupText>
                        <CFormInput
                          type="Number"
                          placeholder="Enter Phone no."
                          autoComplete="off"
                          name="mobile"
                          maxlength="10"
                          value={mobile}
                          onChange={handleInputChange}
                          onFocus={() => handleInputFocus("mobile")}
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 10))
                          }
                        />
                      </CInputGroup>
                      {errors.mobile && (
                        <div className="text-danger">{errors.mobile}</div>
                      )}
                    </CCol>

                    <CCol className="mb-1" md={12}>
                      <label htmlFor="email" className="mb-1">
                        E-mail Address
                        <span className="red-star">{` `}*</span>
                      </label>
                      <CInputGroup className="">
                        <CInputGroupText>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </CInputGroupText>
                        <CFormInput
                          type="email"
                          placeholder="E-mail Address"
                          autoComplete="off"
                          name="email"
                          value={email}
                          onChange={handleInputChange}
                          onFocus={() => handleInputFocus("email")}
                        />
                      </CInputGroup>
                      {errors.email && (
                        <div className="text-danger">{errors.email}</div>
                      )}
                    </CCol>
                    {showPassword ? null : (
                      <CCol className="mb-3" md={12}>
                        <label htmlFor="password" className="mb-1">
                          Password
                          <span className="red-star">{` `}*</span>
                        </label>
                        <CInputGroup className="">
                          <CInputGroupText>
                            <FontAwesomeIcon icon={faLock} />
                          </CInputGroupText>
                          <CFormInput
                            type="password"
                            placeholder="Password"
                            autoComplete="off"
                            name="password"
                            value={password}
                            onChange={handleInputChange}
                            onFocus={() => handleInputFocus("password")}
                          />
                        </CInputGroup>
                        {errors.password && (
                          <div className="text-danger">{errors.password}</div>
                        )}
                      </CCol>
                    )}

                    {/* <label htmlFor="role" className="mb-1">
                      Role
                      <span className="red-star">{` `}*</span>
                    </label>
                    <CFormSelect
                      size="md"
                      className="mb-3"
                      aria-label="select Role"
                      id="role"
                      defaultValue=""
                      name="role"
                      required
                      {...register("role", { required: true })}
                    >
                      <option value="" disabled>
                        Select Role
                      </option>
                      <option value="0">No</option>
                      <option value="1">Yes</option>
                    </CFormSelect> */}
                    <CRow className="">
                      <CCol className="dflex-center gap-2">
                        <CButton onClick={handleSubmitUser} color="primary" className="px-4">
                          Save
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCard>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
      </CModal>
    </>
  );
};

// UserPopForm.propTypes = {
//   data: PropTypes.shape({
//     name: PropTypes.string,
//     email: PropTypes.string,
//     phone: PropTypes.number,
//     role: PropTypes.string,
//   }),
//   onSubmit: PropTypes.func.isRequired,
// };


export default UserPopForm;

