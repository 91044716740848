import React, { useState, useEffect } from "react";
import {
  CFormInput,
  CForm,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormLabel,
  CFormSelect,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import DeletePopMsg from "src/components/modal/DeletePopMsg";
import {
  addHeader,
  getHeaders,
  updateHeader,
  getHeaderById,
  deleteHeader,
} from "src/api/header";
import ReactPaginate from "react-js-pagination";

const Headers = () => {
  const [stateChange, setStateChange] = useState(false);
  const header = {
    headerName: "",
  };
  const [headerAction, setHeaderAction] = useState("create");
  const [headerId, setHeaderId] = useState("");
  const [headerSection, setHeaderSection] = useState(header);
  const [headerData, seHeaderData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [errors, setErrors] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = headerData?.slice(indexOfFirstRow, indexOfLastRow);

  useEffect(() => {
    getHeader();
  }, []);

  const [clicked, setClicked] = useState({
    headerName: false,    
  });

  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleHeader = (e) => {
    const { name, value } = e.target;
    setHeaderSection({ ...headerSection, [name]: value });
  };

  const handleHeaderSection = (data) => {

    let formIsValid = true;
    const errorsCopy = { ...errors };

    if (!headerName) {
      errorsCopy.headerName = "Header Name is required";
      formIsValid = false;
    } else {
      errorsCopy.headerName = "";
    }
    
    setErrors(errorsCopy);

    if(formIsValid){
      const payload = {
        headerName: headerSection.headerName,
      };
  
      if (headerAction === "create") {
        addHeader(payload)
          .then((res) => {
            getHeader();
            setStateChange(!stateChange);
            setHeaderSection(header);
  
            setHeaderAction("create");
          })
          .catch((err) => console.log("err", err));
      } else {
        updateHeader(headerId, payload)
          .then((res) => {
            getHeader();
            setStateChange(!stateChange);
            setHeaderSection(header);
            setHeaderAction("create");
          })
          .catch((err) => console.log("err", err));
      }
    }
    
  };

  const handleInputFocus = (fieldName) => {
    setClicked({ ...clicked, [fieldName]: true });

    setErrors({ ...errors, [fieldName]: "" });
  };

  //get Single HeroSection
  const getSingleHeaders = (id) => {
    getHeaderById(id)
      .then((res) => {
        setHeaderSection(res.data);
      })
      .catch((err) => console.log("err", err));
  };

  const getHeader = () => {
    getHeaders()
      .then((res) => {
        seHeaderData(res?.data);
      })
      .catch((err) => console.log("err", err));
  };
  //delete Hero Section
  const deleteHeaderData = (id) => {
    deleteHeader(id)
      .then((res) => {
        getHeader();
        setHeaderSection(header);
        setVisible(false);
        setHeaderAction("create");
      })
      .catch((err) => console.log("err", err));
  };

  const { headerName } = headerSection;

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  return (
    <>
      <CCard className="mb-4">
        <CCardHeader>
        <strong>
        {headerAction === "create"
          ? "Create Headers"
          : headerAction === "edit"
          ? "Edit Headers"
          : ""}
          </strong>
        </CCardHeader>
        <CCardBody>
          <CForm>
            <CRow>
              <CCol xs={10}>
                <div style={{ display: "flex" }}>
                  <CCol xs={2}>
                    <CFormLabel  htmlFor="headerName">Header Name</CFormLabel>
                    <span className="red-star">{` `}*</span>
                  </CCol>
                  <CCol xs={10}>
                    <CFormInput 
                    // width={"100%"}
                      class="form-control"                 
                      type="text"
                      id="exampleFormControlInput1"
                      name="headerName"
                      placeholder="Header Name"
                      value={headerSection.headerName}
                      onChange={handleHeader}
                      onFocus={() => handleInputFocus("headerName")}
                      aria-describedby="exampleFormControlInputHelpInline"
                    />
                    {errors.headerName && (
                  <div className="text-danger">{errors.headerName}</div>
                )}
                  </CCol>
                </div>
              </CCol>
              <CCol xs={2} className="d-flex justify-content-center align-items-center">
                <CButton onClick={handleHeaderSection}>Submit</CButton>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>

      <CCard className="mb-4">
        <CCardHeader>
          {/* {headerAction === "create"
            ? "Create Headers"
            : headerAction === "edit"
              ? "Edit Headers"
              : ""} */}
          <strong>List All Headers</strong>
        </CCardHeader>

        <div className="search_bar">
          <div className="search-inner">
            {/* {showSelectAndSearch && ( */}
            <div>
              <CFormSelect
                size="sm"
                className="mb-3"
                style={{ cursor: "pointer" }}
                aria-label="Small select example"
                onChange={(e) => setRowsPerPage(e.target.value)}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </CFormSelect>
            </div>
            {/* )} */}
            <div style={{ display: "flex" }}>
              {/* {showSelectAndSearch && ( */}
              <>
                <CFormInput
                  type="search"
                  size="sm"
                  placeholder="Search"
                  aria-label="sm input example"
                  style={{ margin: "0px 0px 16px 0px" }}
                  onChange={handleSearchChange} 
                  value={searchInput}
                />
                <span
                  style={{
                    border: " 1px solid #d1c5c5",
                    borderRadius: "4px",
                    margin: "0px 5px 16px -1px",
                    padding: "1px 8px 0px 8px",
                    background: "#ebedef",
                    cursor: "pointer",
                  }}
                >
                  {/* <i className="fa fa-search"></i> */}
                  <FontAwesomeIcon role="button" icon={faSearch} size="1x" />
                </span>
              </>
              {/* )} */}
            </div>
          </div>
         
        </div>

        <CCardBody className="pb-0">
          <CTable responsive striped hover className="table table-bordered custom-table-border row-hover">
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col" className="col-md-1 purpleRow">
                  S.No.
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="col-md-10 purpleRow">
                  Header Name
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="col-md-1 purpleRow">
                  Action
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
            
            {currentRows?.length > 0 && (
                currentRows
                  ?.filter((item) =>
                    item?.headerName
                      .toLowerCase()
                      .includes(searchInput.toLowerCase())
                  ))?.map((item, index) => {                    
                  return (
                    <CTableRow key={index}>
                      {/* <CTableDataCell>{item.id || "-"}</CTableDataCell> */}
                      <CTableDataCell>{index + 1}</CTableDataCell>
                      <CTableDataCell>{item?.headerName}</CTableDataCell>

                      <CTableDataCell>
                        <>
                          <Tooltip text="Update">
                            <FontAwesomeIcon
                              role="button"
                              icon={faPenToSquare}
                              size="1x"
                              className="px-3 updateIcon"
                              onClick={() => {
                                setHeaderAction("edit");
                                setHeaderId(item?.id);
                                getSingleHeaders(item?.id);
                              }}
                            />
                          </Tooltip>

                          <Tooltip text="Delete">
                            <FontAwesomeIcon
                              onClick={() => {
                                setHeaderAction("create");
                                setHeaderId(item?.id);
                                setVisible(true);
                              }}
                              role="button"
                              icon={faTrash}
                              size="1x"
                              className="deleteIcon"
                            />
                          </Tooltip>
                        </>
                      </CTableDataCell>
                    </CTableRow>
                  );
                })}

              {/* ))} */}
            </CTableBody>
          </CTable>
          <div className="pagin-div">
            <div>
              Showing {indexOfFirstRow + 1} to{" "}
              {Math?.min(indexOfLastRow, headerData?.length)} of{" "}
              {headerData?.length} entries
            </div>
            <div className="pagination-container">
              <ReactPaginate
                activePage={currentPage}
                itemsCountPerPage={rowsPerPage}
                totalItemsCount={headerData?.length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                prevPageText="Previous"
                nextPageText="Next"
                firstPageText="First"
                lastPageText="Last"
                innerClass="pagination"
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </div>
        </CCardBody>

        {/* <br /> */}
        {/* <CCardHeader>
        {headerAction === "create"
          ? "Create Headers"
          : headerAction === "edit"
          ? "Edit Headers"
          : ""}
      </CCardHeader> */}
        {/* <CCardBody> */}
        {/* <Container> */}
        {/* <CForm>
          <CRow className="align-items-start">
            <CCol>
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                label="Header Name"
                name="headerName"
                value={headerSection.headerName}
                onChange={handleHeader}
                aria-describedby="exampleFormControlInputHelpInline"
              />
            </CCol>
          </CRow>
          <br />

          <br />
          <CButton onClick={handleHeaderSection}>Submit</CButton>
        </CForm> */}
        {/* </Container> */}
        {/* </CCardBody> */}

        <DeletePopMsg
          visible={visible}
          setVisible={setVisible}
          id={headerId}
          deleteFunction={deleteHeaderData}
        />
      </CCard>
    </>
  );
};

export default Headers;
